<template>
  <div class="mr-n2">
	<div v-if="treatmentPlansFromRepo.length === 0 && surveyForPatientReport.length === 0" class="d-flex align-center justify-center" style="">
		<span style="border: #e3e3e3 dashed 2px; color:#a3a3a3" class="pa-3 text-subtitle-1">
		{{ $t("thisUserHasNoDevices") }}
		</span>
    </div>
    <v-card class="elevation-3 mx-n1 pa-5">
		<div v-for="item in treatmentPlansFromRepo" v-show="item.status === 1" :key="item.id">
			<div class="ma-0 pa-0">
				<v-row>
					<v-col class="text-left pb-0 d-flex justify-space-between" cols="12">
					<span class="font-size-small-bold-text mb-3 ">{{item.tpName}}</span>
					<div class="d-flex align-center">
						<v-icon small class="pt-1" style="margin-top:-2px;" :class="detailedUser.userData.status !== 1 ? 'd-none' :''" :style="detailedUser.userData.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
						<span class="green-dot-status mr-2 pa-2 text-uppercase">{{ handleActiveText(detailedUser.userData.status) }}</span>
						<v-menu v-if="treatmentPlansFromRepo.length !== 0 && treatmentPlansFromRepo[0].patientId !== null" class="py-0" right bottom offset-y offset-x transition="slide-x-transition">
						<template v-slot:activator="{on, attrs}">
							<v-icon  color="black"  :disabled="detailedUser.userData.status === 99" v-bind="attrs" v-on="on" class="mr-2" >mdi-dots-horizontal</v-icon>
						</template >
						<v-list class="px-2" dense>
							<v-list-item link dense @click="sinhronizeDrawer">
                <v-icon color="main_black" class="pr-1">mdi-pencil</v-icon>
							<v-list-item-content class="font-weight-bold font-size14">{{ $t('monitoring-plan') }}</v-list-item-content>
							</v-list-item>
						</v-list>
              <v-list class="px-2" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item  class="py-0 my-0" link dense @click="assignpatienttodefaultclientmonitoringplan" v-on="on">
                      <img :src="MonitoringPLanBlack" style="width:25px;" class="mr-2">
                      <v-list-item-content class="font-bold" style="font-size: 14px">{{$t('asssign-cmp')}}</v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{$t('assign-to-default-client-monitoring')}}</span>
                </v-tooltip>
              </v-list>
						</v-menu>
            <v-menu v-else class="py-0" right bottom offset-y offset-x transition="slide-x-transition">
              <template v-slot:activator="{on, attrs}">
                  <v-icon  color="black"  v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
              </template >
              <v-list class="px-2" dense>
                <v-list-item class="py-0 my-0" link dense @click="deassignpatientfromclientmonitoringplan">
                  <img :src="MonitoringPLanBlack" style="width:25px;" class="mr-2">
                  <v-list-item-content class="font-bold" style="font-size: 13px">{{$t('deAssignTreatmentPlan')}}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
					</v-col>
          <v-col class="text-left mt-n7" cols="12">
            <span class="font-size16 font-weight-medium">{{item.tpDescription}}</span>
          </v-col>
					<span class="text--secondary font-weight-bold mt-n2 mb-4 font-size13 ml-3">
          {{$t('started')}}: {{ new Date(item.started).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) }}</span>

				</v-row>
			</div>
			<div>
			<div style="display: flex; overflow-x: auto; text-align:center;">
				<div class="mt-2" v-for="survey in surveyForPatientReport" :key="survey.id" >
				<div class="d-flex align-center flex-column">
					<img :src="wellnessIcon" style="min-width:20px; max-width: 20px" />
					<h6 class="font-weight-bold text-center color-black font-size13" style="min-width:200px;">{{survey.templateName}}</h6>
				</div>
				<div class="font-weight-bold  font-size13" >{{$t('measure')}}    {{ getLocalizedRecurrence(survey.recurrenceRuleOfLastRecurrence) }}</div>
				<div class="font-weight-bold " style="color:gray; font-size: 13px;">
          {{ getLocalizedMonths(new Date(survey.timestampOfLastRecurrence).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }))}}
				</div>
				</div>
				<v-card elevation="0" class="my-auto" v-for="(device) in item.treatmentPlanDevicesList" :key="device.id" style="min-width:200px;">
					<div class="d-flex align-center flex-column">
            <v-img max-width="20" class="mr-1" :src="getDeviceIcons(device)"></v-img>
						<span class="font-weight-bold color-black font-size13">{{ getDeviceNames(device)}}</span>
            <span class="text-left font-weight-bold  font-size12">{{$t('measure')}} {{ getLocalizedMeasurementPeriod(device.measurementPeriod) }}</span>
            <v-col cols="12" class="pt-0 pl-0">
              <v-chip-group>
                <v-row dense>
                  <v-col>
                    <div class="chip-grid px-6" :class="device.measurementsCount === 1  ? 'ml-10' :''" :style="device.measurementsCount === 1 ? 'width: 50px;' : ''">
                      <v-chip v-for="(time, index) in getDeviceMeasurementCount(device)"
                              :key="index"
                              small
                              label
                              style="margin:1px"
                              class="dark_purple font-weight-medium text-color-white"
                              :class="{'pl-1': device.measurementsCount === 1 }">
                              <div class="ml-1" :class="{'pl-n7 ml-n1' : device.measurementsCount !==1}">
                                {{ getTimeWithAMPM(time) }}
                              </div>
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-chip-group>
            </v-col>
					</div>
				</v-card>
			</div>
			</div>
		</div>
    </v-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { wellnessAssessmentBlack, monitoringPlanBlack, oxymeterBlack, glucoseMeterBlack, bloodPressureBlack, thermometerBlack, scaleBlack } from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';
export default {
	data () {
		return {
      // icons
      wellnessIcon: wellnessAssessmentBlack,
      oximeterIcon: oxymeterBlack,
      glucoseIcon: glucoseMeterBlack,
      bloodPressureIcon: bloodPressureBlack,
      thermometerIcon: thermometerBlack,
      weightScaleIcon: scaleBlack,
      MonitoringPLanBlack: monitoringPlanBlack,
		};
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
			treatmentPlansFromRepo: (state) => state.treatmentplan.treamentPlans,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
			patientActiveTreatmentPlan: (state) => state.medicaldevice.patientActiveTreatmentPlan,
			surveyForPatientReport: (state) => state.mentalhealth.surveyReportForPatient,
		}),
		...mapGetters({
			userSettings: 'authentication/getUserSettings',
		}),
	},
	updateDrawer () {
		return this.$store.state.updateDrawer;
	},
	userData () {
		return this.detailedUser;
	},
	async mounted () {
		const header = { domainsList: 'MEDICAL_DEVICE_MANUFACTURER' };
		await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
		await this.initialize();
    await this.getSurveys();
	},
	methods: {
    handleActiveText (status) {
      switch (status) {
        case -99:
        case -1:
          return '';
        case 1:
            return 'Active';
      }
    },
    getDeviceMeasurementCount (device) {
      switch (device.measurementsCount) {
        case 1:
          return [device.time1];
        case 2:
          return [device.time1, device.time2];
        case 3:
          return [device.time1, device.time2, device.time3];
        case 4:
          return [device.time1, device.time2, device.time3, device.time4];
      }
    },
    getDeviceNames (device) {
      switch (device.deviceType) {
        case 5 :
          return this.$t('weight-scale');
        case 3:
          return this.$t('blood-pressure');
        case 2:
          return this.$t('oximeter');
        case 1:
          return this.$t('thermometer');
        case 6 :
          return this.$t('glucose-meter');
      }
    },
    getDeviceIcons (device) {
      switch (device.deviceType) {
        case 5 :
          return this.weightScaleIcon;
        case 3:
          return this.bloodPressureIcon;
        case 2:
          return this.oximeterIcon;
        case 1:
          return this.thermometerIcon;
        case 6 :
          return this.glucoseIcon;
      }
    },
    getLocalizedMonths (dateString) {
      const date = new Date(dateString);
      const day = date.toLocaleString('en-US', { day: 'numeric' });
      const month = date.toLocaleString(this.$i18n.locale, { month: 'short' });
      const year = date.toLocaleString('en-US', { year: 'numeric' });
      return `${month} ${day}, ${year}`;
    },
    getRecurrenceLocalized (reports) {
      if (this.$i18n.locale === 'es') {
        switch (reports) {
          case 'Daily':
            return 'A diario';
          case 'Every 4th Day':
            return 'Cada 4 días';
          case 'Monthly':
            return 'Mensual';
          case 'Every 2nd Day':
            return 'Cada 2 días';
          case 'Every 3rd Day':
            return 'Cada 3er día';
          case 'Once':
            return 'Una vez';
        }
      }
      return reports;
    },
    getLocalizedRecurrence (recurrenceRule) {
      return this.getRecurrenceLocalized(recurrenceRule);
    },
      deassignpatientfromclientmonitoringplan () {
      const body = {
        treatmentPlanId: this.treatmentPlansFromRepo[0].id,
        patientUserId: this.detailedUser.userData.id,
      };
        this.$store.dispatch('treatmentplan/deassignpatientfromclientmonitoringplan', body).then(res => {
          if (res.resFlag) {
            showSuccessAlert(this.$t('success-operation'));
            this.initialize();
            this.getSurveys();
          } else {
            showErrorAlert(this.$t('failed-operation'));
          }
        });
    },
    assignpatienttodefaultclientmonitoringplan () {
      const body = {
        patientUserId: this.detailedUser.userData.id,
      };
      this.$store.dispatch('treatmentplan/assignpatienttodefaultclientmonitoringplan', body)
          .then(res => {
            if (res.resFlag) {
              showSuccessAlert(this.$t('success-operation'));
              this.initialize();
              this.getSurveys();
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(error => {
            showErrorAlert(this.$t('failed-operation'));
          });
    },
    async getSurveys () {
      const headers = {
        patientId: this.detailedUser.userData.id,
        filterCase: this.$cookies.get('datafilter'),
        limitForOccurrences: 50,
      };
      await this.$store.dispatch('mentalhealth/getSurveyReportForPatient', headers);
    },
		getLocalizedMeasurementPeriod (period) {
			if (period === 'M') {
				return this.$t('monthly');
			} else if (period === 'W') {
				return this.$t('weekly');
			} else if (period === 'D') {
				return this.$t('daily');
			} else {
				return '';
			}
		},
		getTimeWithAMPM (time) {
			const [hours, minutes] = time.split(':');
			const formattedTime = `${hours}:${minutes} ${this.getAMPM(hours)}`;
			return formattedTime;
		},
		getAMPM (hours) {
			return hours >= 12 ? 'PM' : 'AM';
		},
		sinhronizeDrawer () {
			this.$store.commit('SET_PATIENT_DETAILS_DRAWER', 'monitoringPlansEdit');
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
				updateDrawer: true,
				page2: 'patientdetails',
			});
		},
		async initialize () {
			await this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then(res => {
				if (res.data.patientActiveTreatmentPlan) {
					this.$store.commit('treatmentplan/SET_TREATMENT_PLAN_FOR_PATIENT', [res.data.patientActiveTreatmentPlan]);
				}
			});
		},
	},
};
</script>
<style>
.chip-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
  margin: 0;
  padding: 0;
  gap:0
}
</style>
