<template>
  <v-dialog v-model="localDialog" max-width="380px" transition="dialog-top-transition">
    <v-card class="pa-7">
      <v-card-title class="d-flex justify-center align-center">
        <v-row dense>
          <v-col class="py-0 mb-1" cols="12">
            <span class="icon-background-alert">
              <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
            </span>
          </v-col>
          <v-col cols="12">
            <span>{{ $t('deleteQuestion') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn block depressed color="main_red" class="text-color-white" @click="deleteSurveyConfirmation">{{ $t('deleteField') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  name: 'AssessmentsDeleteDialog',
  props: ['dialog', 'surveyId'],
  data () {
    return {
      localDialog: this.dialog,
      localSurveyId: this.surveyId,
    };
  },
  watch: {
    dialog (val) {
      this.localDialog = val;
    },
    surveyId (val) {
      this.localSurveyId = val;
    },
    localDialog (val) {
      this.$emit('update:dialog', val);
    },
    localSurveyId (val) {
      this.$emit('update:surveyId', val);
    },
  },
  methods: {
    deleteSurveyConfirmation () {
      this.$store.dispatch('mentalhealth/deleteSurveysById', this.localSurveyId).then((res) => {
        if (res.resFlag) {
          showSuccessAlert(this.$t('surveyDeleted'));
          this.loadData();
        } else {
          showErrorAlert(this.$t('delete-assesmet-error'));
        }
        this.closeDeleteDialog();
      });
    },
    closeDeleteDialog () {
      this.localDialog = false;
      this.localSurveyId = '';
    },
    loadData () {
      const hospital = localStorage.getItem('selectedHospitalAssignedId');
      this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', hospital);
    },
  },
};
</script>
