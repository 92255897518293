<template>
    <v-card class="pa-0" :style="dialog? 'margin-left:15px; margin-top:15px; !important' : ''" >
      <div class="font-size16 text-left py-2 ml-2 font-weight-bold mt-7">
        {{$t('PHQ-table-header')}}
      </div>
      <v-data-table
          hide-default-footer
          :headers="headers"
          :items="items"
          class="elevation-1 mx-0 m-"
          dense
      >
        <template v-slot:top>
          <div>
            <v-toolbar-title class="font-size15 text-left py-2 ml-2">{{$t('PHQ-table-description')}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </div>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
export default {
  props: ['dialog'],
  data () {
    return {
      headers: [
        { text: this.$t('PHQ-header-1'), align: 'start', value: 'score' },
        { text: this.$t('PHQ-header-2'), value: 'severity' },
        { text: this.$t('PHQ-header-3'), value: 'actions' },
      ],
      items: [
        {
          score: '0 - 4',
          severity: this.$t('PHQ-item-1'),
          actions: this.$t('PHQ-item-description-1'),
        },
        {
          score: '5 - 9',
          severity: this.$t('PHQ-item-2'),
          actions: this.$t('PHQ-item-description-2'),
        },
        {
          score: '10 - 14',
          severity: this.$t('PHQ-item-3'),
          actions: this.$t('PHQ-item-description-3'),
        },
        {
          score: '15 - 19',
          severity: this.$t('PHQ-item-4'),
          actions: this.$t('PHQ-item-description-4'),
        },
        {
          score: '20 - 27',
          severity: this.$t('PHQ-item-5'),
          actions:
              this.$t('PHQ-item-description-5'),
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-data-table {
  border: 1px solid #ddd;
}
.v-toolbar-title {
  font-weight: bold;
}
</style>
