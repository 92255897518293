<template>
  <v-container fluid>
    <v-row dense>
      <v-dialog
          v-model="dialog"
          max-width="350">
        <v-card>
          <v-card-title  class="font-size16 white--text my-0 py-0" style="background-color:#a51717; height:40px !important;">
            {{ $t('create-staff') }}
          </v-card-title>
          <v-card-text class="font-size15 d-flex flex-column mt-2">
            {{ $t('are-you-sure-the-staff') }} "{{staff?.userFirstName}} {{staff?.userLastName}}" {{ $t("is-not-mapped") }} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="dialog = false">
              {{$t('cancel')}}
            </v-btn>
            <v-btn class="white--text" small color="chips" @click="confirmCreate">
              {{$t('create')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="deleteDialog"
          max-width="350">
        <v-card>
          <v-card-title  class="font-size16 white--text my-0 py-0" style="background-color:#a51717; height:40px !important;">
            {{ $t('delete-ecp-staff-mapping') }}
          </v-card-title>
          <v-card-text class="font-size15 d-flex flex-column">
            {{ $t('are-you-sure-you-want-to-delete-this-staff-mapping') }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="deleteDialog = false">
              {{$t('cancel')}}
            </v-btn>
            <v-btn class="white--text" small color="chips" @click="confirmDeletion">
              {{$t('delete')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- TODO:  With Update Table -->
      <v-col cols="6">
        <v-card elevation="5" :loading="false">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="ecp" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('with-update')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchEcpMedicalStaff"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-radio-group class="small"  v-model="selectedEcpUser">
              <v-data-table
                  :loading="loading"
                  :headers="ecpHeaders"
                  :items="unfiltered.usersNeedToBeMapped"
                  :search="searchEcpMedicalStaff"
                  height="27vh"
                  fixed-header
                  class="mt-n5"
                  :footer-props= "getDataTableFooterProps('medicalStaffMappingTable')"
                  :items-per-page="-1"
                  :no-data-text="$t('no-data-available')"
              >
                <template v-slot:item="{ item, index }">
                  <tr :class="getOddRows(index)">
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                      <div class="d-flex">
                        <v-img  class="mr-2" :src="PatientBlack" max-width="13px" max-height="20px"/>
                        {{ item.userFirstName }} {{item.userLastName}}
                      </div>
                    </td>
                    <td style="height:30px !important;" class="table-font-size">
                      {{item.userEmail}}
                    </td>
                    <td style="height:30px !important;" class="table-font-size">
                      {{item.userPhoneCell}}
                    </td>
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                    <div class="d-flex justify-end align-center">
                      {{ item.userRole }}
                      <v-radio :value="item" @click="filterByRole(item)"></v-radio>
                    </div>
                    </td>
                    <td style="height:30px !important;" class="table-font-size pl-8 py-0">
                      <v-btn  x-small color="primary" class="text-capitalize" @click="createStaff(item)">{{$t('create')}}</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-radio-group>
          </div>
        </v-card>
      </v-col>
      <!-- TODO:  Not Mapped Table -->
      <v-col cols="6" class="mb-1">
        <v-card elevation="5" :loading="false">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="logoDrawer" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('not-mapped-staff')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchNotMappedMedicalStaff"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-radio-group v-model="selectedNotMappedUser" class="small">
              <v-data-table
                  :loading="loading"
                  :headers="staffHeaders"
                  :items="itemsFiltered.notMappedUsers.length === 0 ? unfiltered.notMappedUsers : itemsFiltered.notMappedUsers"
                  :search="searchNotMappedMedicalStaff"
                  height="27vh"
                  fixed-header
                  class="mt-n5"
                  :footer-props= "getDataTableFooterProps('medicalStaffMappingTable')"
                  :items-per-page="-1"
                  :no-data-text="$t('no-data-available')"
              >
                <template v-slot:item="{ item , index }">
                  <tr :class="getOddRows(index)">
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                      <div class="d-flex">
                        <v-icon
                            style="font-size:12px !important;"
                            class="mr-1"
                            x-small
                            :class="getStatusIcons(item.status)"
                        >
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <v-img  class="mr-2" :src="PatientBlack" max-width="13px" max-height="20px"/>{{ item.firstName }} {{item.lastName}}
                      </div>
                    </td>
                    <td  style="height:30px !important;" class="table-font-size">
                      {{item.email}}
                    </td>
                    <td  style="height:30px !important;"  class="table-font-size">
                      {{item.phone}}
                    </td>
                    <td style="height:30px !important;" class="table-font-size px-2 py-0 pr-6">
                      <div class="d-flex justify-end align-center">
                      {{ getRoleName(item.role) }}
                      <v-radio v-if="showRadioButton" :value="item"></v-radio>
                      </div>
                    </td>

                  </tr>
                </template>
              </v-data-table>
            </v-radio-group>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- TODO:  BUTTON -->
    <div>
    <v-btn v-if="selectedEcpUser !== null && selectedNotMappedUser !== null" @click="mapUser" color="primary2" class="full-width my-2">
      <span style="text-transform: capitalize;" class="font-size15 text-color-white">Map User</span>
    </v-btn>
    </div>
    <v-row dense class="my-0 py-0">
      <!--   TODO: MAPPED USERS   -->
      <v-col cols="12">
        <v-card elevation="5" :loading="false">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img class="ml-2" :src="DoctorBlack" max-width="18px" max-height="20px"/>
              <div class="text-left my-2 ml-1 mb-2 font-weight-bold font-size16">{{$t('mapped-staff')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchMappedMedicalStaff"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-data-table
                :loading="loading"
                :headers="mappedUsersHeaders"
                :items="unfiltered.mappedUsers"
                :search="searchMappedMedicalStaff"
                height="33vh"
                fixed-header
                class="mt-n3"
                :footer-props= "getDataTableFooterProps('medicalStaffMappingTable')"
                :items-per-page="-1"
                :no-data-text="$t('no-data-available')"
            >
              <template v-slot:item="{item , index}">
                <tr :class="getOddRows(index)">
                  <td style="height:30px !important;" class="table-font-size px-2 py-0">
                    <div class="d-flex align-center">
                      <v-img  class="mr-2" :src="PatientBlack" max-width="13px" max-height="20px"/>{{ item.firstName }} {{item.lastName}}
                    </div>
                  </td>
                  <td style="height:30px !important;" class="table-font-size px-2 py-0">
                    <div class="d-flex justify-end align-center">
                    {{ getRoleName(item.role) }}
                    <v-icon @click="openDeleteDialog(item)" size="18" color="primary">mdi mdi-delete</v-icon>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ecp, logoDrawer, patientBlack, doctorBlack } from '@/assets';
import MappingRepository from '@/data/repositories/v1/MappingRepository.js';
import UsersRepository from '@/data/repositories/v1/UsersRepository';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
  data () {
    return {
      PatientBlack: patientBlack,
      DoctorBlack: doctorBlack,
      deleteDialog: false,
      patientToDelete: null,
      staff: null,
      dialog: false,
      loading: false,
      showRadioButton: false,
      selectedEcpUser: null,
      selectedNotMappedUser: null,
      searchEcpMedicalStaff: '',
      searchNotMappedMedicalStaff: '',
      searchMappedMedicalStaff: '',
      unfiltered: {
        notMappedUsers: [],
        mappedUsers: [],
        usersNeedToBeMapped: [],
      },
      itemsFiltered: {
        notMappedUsers: [],
        mappedUsers: [],
        usersNeedToBeMapped: [],
      },
      ecpHeaders: [
        { text: this.$t('name'), value: 'userFirstName', class: 'height15-black' },
        { text: this.$t('email'), value: 'email', class: 'height15-black' },
        { text: this.$t('phone'), value: 'phone', class: 'height15-black' },
        { text: this.$t('role'), value: 'userRole', class: 'height15-black pr-9', align: 'end' },
        { text: '', value: '', class: 'px-0 py-0 height15-black', width: '50' },
      ],
      staffHeaders: [
        { text: this.$t('name'), value: 'firstName', class: 'height15-black' },
        { text: this.$t('email'), value: 'firstName', class: 'height15-black' },
        { text: this.$t('phone'), value: 'firstName', class: 'height15-black' },
        { text: this.$t('role'), value: 'role', class: 'height15-black pr-9', align: 'end' },
      ],
      mappedUsersHeaders: [
        { text: this.$t('name'), value: 'firstName', class: 'height15-black' },
        { text: this.$t('role'), value: 'role', class: 'height15-black pr-9', align: 'end' },
      ],
    };
  },
  computed: {
    logoDrawer () {
      return logoDrawer;
    },
    ecp () {
      return ecp;
    },
    ...mapState({
      userData: (state) => state.authentication.userData,
      selectedHospitalId: (state) => state.filterbar.hospitalId,
      medicalInstitution: (state) => state.filterbar.medicalInstitution,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      userSettings: 'authentication/getUserSettings',
    }),
  },
  created () {
    if (this.roleName === 'HOSPITAL_ADMIN') {
      this.getUsersForMapping();
    } else {
      if (this.selectedHospitalId !== null) {
        this.getUsersForMapping();
      }
    }
  },
  watch: {
    selectedHospitalId: {
      handler (val) {
          this.getUsersForMapping();
      },
      deep: true,
    },
  },
  methods: {
    getStatusIcons (status) {
      switch (status) {
        case 1:
          return 'color-green';
        default:
          return '';
      }
    },
    getOddRows (index) {
      if (index % 2 === 1) {
        return 'odd-row';
      }
      if (index % 2 === 0) {
        return 'even-row';
      }
    },
    getDataTableFooterProps,
    confirmCreate () {
      UsersRepository.createStaff(this.staff.id)
          .then(() => {
            this.getUsersForMapping();
            this.dialog = false;
          });
    },
    createStaff (item) {
      this.dialog = true;
      this.staff = item;
    },
    confirmDeletion () {
      MappingRepository.deleteMappedUser(this.patientToDelete).then(() => {
        this.getUsersForMapping();
        this.searchEcpPatient = '';
        this.searchNotMappedPatient = '';
        this.searchMappedPatient = '';
        this.selectedNotMappedUser = null;
        this.selectedEcpUser = null;
        this.deleteDialog = false;
      });
    },
    openDeleteDialog (item) {
    this.patientToDelete = item.id;
    this.deleteDialog = true;
    },
    filterByRole (item) {
      var tempNotMappedUsers = this.unfiltered.notMappedUsers;
      if (item.userRole === 'Caregiver') {
        this.itemsFiltered.notMappedUsers = tempNotMappedUsers.filter(user => user.role === 7);
        this.showRadioButton = true;
      }
      if (item.userRole === 'Nursing') {
        this.itemsFiltered.notMappedUsers = tempNotMappedUsers.filter(user => user.role === 4);
        this.showRadioButton = true;
      }
    },
    getRoleName (roleId) {
      switch (roleId) {
        case 3:
          return 'Doctor';
        case 4:
          return 'Nurse';
        case 7:
          return 'Caregiver';
        default:
          return '';
      }
    },
    calculateAge (dateString) {
      var today = new Date();
      var birthDate = new Date(dateString?.split('T')[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getUsersForMapping () {
      this.loading = true;
      let selectedHospitalId = null;
      if (this.roleName === 'HOSPITAL_ADMIN') {
        selectedHospitalId = this.userData.hospitalAssignedData.id;
      } else {
        selectedHospitalId = this.selectedHospitalId;
      }

      MappingRepository.getMappedEcpMedicalStaffForHospital(selectedHospitalId).then(response => {
        this.unfiltered = response;
        this.loading = false;
        this.itemsFiltered.notMappedUsers = this.unfiltered.notMappedUsers;
        this.showRadioButton = false;
      });
    },
    mapUser () {
      this.loading = true;
      let selectedHospitalId = null;
      let selectedOrganizationId = null;
      if (this.roleName === 'HOSPITAL_ADMIN') {
        selectedHospitalId = this.userData.hospitalAssignedData.id;
        selectedOrganizationId = this.userData.organizationId;
      } else {
        selectedHospitalId = this.selectedHospitalId;
        selectedOrganizationId = this.medicalInstitution;
      }

      const body = {
        ecpUserId: this.selectedEcpUser?.userId || 'string',
        mappedUserId: this.selectedNotMappedUser?.id || 0,
        mappedHospitalId: selectedHospitalId,
        mappedOrganizationId: selectedOrganizationId,
        firstName: this.selectedEcpUser?.userFirstName,
        lastName: this.selectedEcpUser?.userLastName,
      };

      MappingRepository.mapUser(body)
          .then(response => {
            if (response.resFlag) {
              this.getUsersForMapping();
              this.selectedNotMappedUser = null;
              this.selectedEcpUser = null;
              this.searchEcpMedicalStaff = '';
              this.searchNotMappedMedicalStaff = '';
              this.searchMappedMedicalStaff = '';
            } else this.loading = false;
          });
    },
    deleteMappedUser (item) {
      MappingRepository.deleteMappedUser(item.id).then(() => {
        this.getUsersForMapping();
        this.searchEcpMedicalStaff = '';
        this.searchNotMappedMedicalStaff = '';
        this.searchMappedMedicalStaff = '';
        this.selectedNotMappedUser = null;
        this.selectedEcpUser = null;
      });
    },

  },
};
</script>

<style>
.v-messages{
  display:none !important;
}

.small i {
  font-size: 18px !important;
}

.small [class*="__ripple"] {
  left: 0;
  display:none;
}
</style>
