<script>
export default {
  name: 'AlcoholScreeningQuestionnaire.vue',
  props: ['dialog'],
  data () {
    return {

    };
  },
};
</script>

<template>
  <v-card
    elevation="5"
    :class="$vuetify.breakpoint.lgAndDown ? 'pa-2' : 'py-5 px-2'"
    :style="dialog? 'margin-left:15px; !important' : ''"
  >
    <div class="text-left">
      <span class="font-weight-bold">{{ $t("scoring-AUDIT") }}</span>
      <br />
      <br />
      <span class="font-weight-medium font-size13">{{
        $t("AUDIT-list-1")
      }}</span>
      <br />
      <span class="font-weight-medium font-size13">{{
        $t("AUDIT-list-2")
      }}</span>
      <br />
      <span class="font-weight-medium font-size13">{{
        $t("AUDIT-list-3")
      }}</span>
    </div>
  </v-card>
</template>

<style scoped></style>
