<template>
  <div class="overflow-y-auto overflow-x-hidden height90">
    <v-card-title class="text-left card-header fixed-card-title">
      <v-row>
        <v-col cols="10" class="align-center d-flex">
          <v-toolbar-title>{{$t('edit-monitoring-plan')}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-spacer></v-spacer>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="margin-top90 mb-n10">
      <v-text-field
          v-model="patientActiveTreatmentPlan.tpName"
          :label= "$t('change-title')"
          dense
          outlined
          hide-details
          @change="handleTitleChange"
      ></v-text-field>
      <v-textarea
          v-model="patientActiveTreatmentPlan.tpDescription"
          outlined
          :label= "$t('change-description')"
          hide-details
          class="pt-3"
          no-resize
          @change="handleDescriptionChange"
      ></v-textarea>
    </v-card-text>
    <v-card-text>
      <v-col class="px-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="full-width mt-1"
                color="transparent"
                v-on="on"
                :loading="loadingDevices"
                v-bind="attrs">
              {{$t('add-measurement')}}
            </v-btn>
          </template>
          <v-list class="pa-0 ma-0" style="border-radius:0px !important;">
            <template v-if="filteredMedicalDevices.length > 0">
              <v-list-item
                  v-for="(item, index) in filteredMedicalDevices"
                  :key="index"
                  @click="addingDevice(item)"
              >
                <div class="font-size-small2 d-flex align-center">
                  <v-img
                      max-width="18"
                      class="mr-2"
                      :src="getDeviceIcon(item.deviceType)"
                  ></v-img>
                  <h6 class="text-left font-size13 mt-1">
                    {{ $i18n.locale === 'en' ? item.deviceName : item.deviceName }}
                  </h6>
                </div>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item class="text-center d-flex align-center justify-center">
                <h2 class="text-center font-size13 mt-1">{{$t('devices-not-found')}}</h2>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-progress-circular
            v-if="loadingMeasurements"
            class="mt-3"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-col>
      <v-expansion-panels>
        <v-expansion-panel v-for="item in combinedDevices" :key="item.id">
          <!-- Panel Header -->
          <v-expansion-panel-header>
            <div class="d-flex py-2 align-center justify-space-between w-100">
              <div class="d-flex align-center">
                <v-img
                    max-width="18"
                    class="mr-2 ml-1"
                    :src="getDeviceIcon(item.deviceType)"
                ></v-img>
                <h6 class="font-size-small3">
                  {{ $i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp }} {{ item.manufacturer }}
                </h6>
              </div>
            </div>
          </v-expansion-panel-header>

          <!-- Panel Content -->
          <v-expansion-panel-content>
            <div class="padding10">
              <v-row>
                <v-col
                    v-for="(key, i) in item.deviceDefaultValuesJsonNames"
                    :key="i"
                    cols="6"
                    class="px-0 py-0"
                >
                  <v-text-field
                      type="number"
                      hide-details
                      outlined
                      dense
                      class="mx-1 py-1"
                      :rules="[v => !!v || 'This field is required']"
                      v-model="item.deviceDefaultValuesJson[key]"
                      :label="stringFormater(key) + ' ' + unitFormater(key, item)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-row class="mx-1 my-1">
                  <v-select
                      outlined
                      dense
                      hide-details
                      v-model="item.monitoringDetails.measurementsCount"
                      :items="selectiontimes"
                      item-text="name"
                      item-value="id"
                      style="z-index:10000"
                      :menu-props="{ offsetY: true }"
                  ></v-select>
                  <!-- Conditional Time Pickers -->
                  <v-col
                      v-for="count in item.monitoringDetails.measurementsCount"
                      :key="count"
                      cols="6"
                      class="px-0 mx-0"
                  >
                    <v-menu
                        :ref="'menu' + count"
                        style="z-index:10000"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="item.monitoringDetails[`time${count}`]"
                            :label="'Picker ' + count"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            hide-details
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="`menu${count}`"
                          v-model="item.monitoringDetails[`time${count}`]"
                          full-width
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
              <v-card-actions class="pa-0 ma-0">
                <v-icon
                    color="primary"
                    @click="deleteMedicalDevice(item)"
                    class="mt-1 ml-n2">mdi-delete
                </v-icon>
                <v-btn-toggle
                    row
                    mandatory
                    v-model="item.isManualMeasurement"
                    class="white"
                    active-class="chips white--text"
                >
                  <v-btn
                    x-small
                     :value="true"
                    @click="isManual(item)"
                  >{{$t("manual")}}</v-btn>
                  <v-btn
                      x-small
                      :value="false"
                     @click="isManual(item)"
                  >{{$t("automatic")}}</v-btn>
                </v-btn-toggle>

                <!--                <v-icon-->
<!--                    class="mt-1"-->
<!--                    @click="isManual(item)"-->
<!--                    style="font-size:22px !important;"-->
<!--                    color="primary"-->
<!--                >-->
<!--                  {{ item.isManualMeasurement ? 'mdi-radiobox-marked' : 'mdi-radiobox-marked' }}-->
<!--                </v-icon>-->
<!--                <span class="ml-1 mt-1">-->
<!--                {{ item.isManualMeasurement ? 'Manual' : 'Automatic' }}-->
<!--          </span>-->
                <v-btn
                    class="ml-auto mt-1"
                    color="primary"
                    small
                    @click="saveParameters(item)"
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <div v-if="detailedUser.userData.isRtmEnabled">
      <div class="px-4 mt-n2">
        <v-btn color="transparent" @click="openWellnessCard" class="py-5 full-width">+ {{$t('assessments')}}</v-btn>
      </div>
      <v-col class="px-4">
        <div class="text-left" v-if="openWellness">
          <v-card elevation="5">
            <div class="d-flex pa-2">
              <img :src="wellnessAssessmentBlack" class="small-height">
              <div class="color-black font-size13 font-weight-bold mt-1 ml-3">{{$t('wellness-assessments')}}</div>
            </div>
            <v-col cols="12">
              <v-menu ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :label="$t('start-date')"
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    range
                    v-model="date"
                    no-title
                    scrollable
                >
                  <v-btn color="primary" @click="menu = false">{{ $t('cancel') }}</v-btn>
                  <v-btn color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <div class="pa-2">
              <v-select
                  outlined
                  dense
                  :label="$t('Assessments')"
                  :items="filteredSurveyTemplates"
                  item-text="templateName"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                  v-model="selectedTemplate">
              </v-select>
              <v-select
                  outlined
                  dense
                  :label="$t('measurement-period')"
                  style="margin-top:-10px;"
                  :items="reversedSurveyPeriods"
                  v-model="selectedPeriod"
                  item-text="shortDescription"
                  :menu-props="{ offsetY: true }"
                  item-value="lowValue">
              </v-select>
              <v-btn
                  @click="assignSurveyTemplatetoPatient"
                  class="save-button-style">
                {{ $t("save") }}
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-col>
      <div class="mb-3 text-center">
        <v-btn-toggle
            v-if="!openWellness && surveyForPatientReport.length > 0"
            v-model="assessmentStatus"
            row
            active-class="chips white--text"
            mandatory
            class="white mb-1"
        >
          <v-btn
              class="tabs-class"
              :value="1"
              style="width: 218px !important;"
              :input-value="assessmentStatus"
              @click="resetFlag"
          >{{$t("active")}}</v-btn>
          <v-btn
              class="tabs-class"
              :value="0"
              style="width: 218px !important;"
              :input-value="assessmentStatus"
              @click="resetFlag"
          >{{$t("inactive")}}</v-btn>
        </v-btn-toggle>
      </div>
      <v-card elevation="0" height="30vh" class="overflow-y-auto overflow-x-hidden">
        <v-col  v-for="(item, index) in filteredSurveyForPatientReport" :key="index" class="py-0 mx-1">
          <div v-for="(recurrence) in item.surveysList" :key="recurrence.id" class="py-1"  v-show="selectedCardIndex === recurrence.id || !clickedEdit">
            <v-card v-if="!openWellness">
              <v-row class="px-5 mb-2" >
                <v-col cols="11">
                  <v-row class="d-flex align-center" style="height:30px;">
                    <!--        first line-->
                    <v-icon  :class="{ 'green-icon': recurrence.status }" small>mdi-checkbox-blank-circle</v-icon>
                    <img :src="wellnessAssessmentBlack" style="height:20px;">
                    <span class="font-size12-5 font-weight-bold">{{recurrence.templateName}}</span>
                    <v-switch
                        class="ml-auto my-3"
                        dense
                        hide-details
                        v-model="recurrence.status"
                        @change="disableSurvey(recurrence)"
                    ></v-switch>
                  </v-row>
                  <v-row class="d-flex align-center" style="height:30px;">
                    <!--      second line  -->
                      <span class="font-size12-5 font-weight-bold">
                    {{$t('start-date')}} : {{recurrence.startDate}}
                  </span>
                    <div class="font-size12-5 font-weight-bold mx-2" v-if="recurrence.endDate !== null">
                      {{$t('end-date')}} {{recurrence.endDate}}
                    </div>
                    <div class="font-size12-5 font-weight-bold ml-auto mx-3">
                      {{recurrence.recurrenceRuleForSurveyTitle}}
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="1" class="d-flex align-center justify-center">
                  <v-icon @click.stop="userEditing(recurrence)" v-if=!clickedEdit class=" color-primary  cursor" flat>mdi mdi-chevron-down</v-icon>
                  <v-icon @click.stop="cancelEditing" v-if=clickedEdit class=" cursor color-primary">mdi mdi-chevron-up</v-icon>
                </v-col>
              </v-row>
              <div v-if="clickedEdit" class="mt-2 pa-1 text-left">
                <span class="font-size12 text-left font-weight-bold mb-1">{{recurrence.templateDescription}}</span>
                <v-select class="mx-2"
                          hide-details
                          outlined
                          dense
                          :label="$t('recurrence')"
                          :items="reversedSurveyPeriods"
                          v-model="selectedPeriodEdit"
                          item-text="shortDescription"
                          item-value="lowValue"
                ></v-select>

                <v-menu :ref="'menu_' + recurrence.id" v-model="recurrence.menuOpen" :close-on-content-click="false" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field  class="mt-1 mr-2"
                                   :label="$t('start-date')"
                                   v-model="date2"
                                   prepend-icon="mdi-calendar"
                                   readonly
                                   hide-details
                                   dense
                                   outlined
                                   v-bind="attrs"
                                   v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker range v-model="date2" scrollable>
                    <v-btn text class="color-primary" @click="closeMenu(recurrence)">{{$t('cancel')}}</v-btn>
                    <v-btn text class="color-primary" @click="closeMenu(recurrence)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="d-flex align-center justify-end pb-1 mr-2" v-if="clickedEdit">
                <!--            <v-switch-->
                <!--                class="ml-1"-->
                <!--                v-model="recurrence.status"-->
                <!--                @change="disableSurvey(recurrence)"-->
                <!--            ></v-switch>-->
                <v-btn class="mr-2 text-color-white"
                       @click="updateSurvey(recurrence)"
                       color="primary2"
                       small
                        :ripple="false"
                       :disabled="selectedPeriodEdit===null || date2 === null"
                >{{$t('save')}}</v-btn>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-card>
    </div>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="save"
              class="save-button-style"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  wellnessAssessmentBlack,
  bloodPressureBlack,
  cholesterolMeterBlack,
  glucoseMeterBlack,
  oxymeterBlack,
  scaleBlack,
  thermometerBlack,
} from '@/assets';

import { fromTo } from '@/utils/unit-converter.js';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';
export default {
  data () {
    return {
      testing: null,
      manualMeasurement: null,
      loadingDevices: false,
      loadingMeasurements: false,
      title: '',
      description: '',
      // icons
      wellnessAssessmentBlack: wellnessAssessmentBlack,
      thermometer: thermometerBlack,
      bloodPressure: bloodPressureBlack,
      oximeter: oxymeterBlack,
      glucoseMeter: glucoseMeterBlack,
      weightScale: scaleBlack,
      cholesterolMeter: cholesterolMeterBlack,
      descriptionChanges: false,
      titleChanges: false,
      date: null,
      date2: null,
      selectedTemplate: null,
      selectiontimes: [
        { name: this.$t('one-time-per-day'), id: 1 },
        { name: this.$t('two-time-per-day'), id: 2 },
        { name: this.$t('three-time-per-day'), id: 3 },
        { name: this.$t('four-time-per-day'), id: 4 },
      ],
      menu: false,
      selectedPeriod: null,
      openWellness: false,
      clickedEdit: false,
      assessmentStatus: true,
      selectedPeriodEdit: null,
      selectedCardIndex: -1,
      showInputs: true,
      time1: null,
      menu1: false,
      modal1: false,
      time2: null,
      selectedRecurrence: null,
      menu2: false,
      modal2: false,
      time3: null,
      menu3: false,
      modal3: false,
      time4: null,
      menu4: false,
      modal4: false,
      formChangeTemplate: '',
      formChangeDescription: '',
      trustedHubIdentifier: null,
    };
  },
  computed: {
    ...mapGetters({
      userSettings: 'authentication/getUserSettings',
    }),
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      surveyTemplates: (state) => state.mentalhealth.surveys,
      patientActiveTreatmentPlan: (state) => state.medicaldevice.patientActiveTreatmentPlan,
      surveyPeriods: (state) => state.verifiers.surveyRecurrence,
      surveyForPatientReport: (state) => state.mentalhealth.surveyReportForPatient,
      auth: (state) => state.authentication.userData,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
      combinedDevices: (state) => state.medicaldevice.combinedDevices,
      medicalDevices: (state) => state.medicaldevice.medicalDevices,
      hubIdentifier: (state) => state.medicaldevice.hubIdentifier,
    }),
    filteredSurveyForPatientReport () {
      // Filter based on assessmentStatus
      return this.surveyForPatientReport
          .map((surveyGroup) => {
            return {
              ...surveyGroup,
              surveysList: surveyGroup.surveysList.filter((recurrence) => {
                if (this.assessmentStatus === 0) {
                  // Show only items with status 0
                  return recurrence.status === 0;
                } else if (this.assessmentStatus === 1) {
                  // Show everything except status 0
                  return recurrence.status !== 0;
                }
              }),
            };
          })
          .filter((group) => group.surveysList.length > 0); // Remove groups with no surveys
    },
    filteredMedicalDevices () {
      return this.medicalDevices.filter(device => {
        // Filter out devices that are already assigned or don't match manufacturerId === 1
        return device.manufacturerId === 1 && !this.combinedDevices.some(assignedDevice =>
            assignedDevice.deviceType === device.deviceType,
        );
      });
    },
    filteredSurveyTemplates () {
      return this.surveyTemplates.filter(item => item.status === 1);
    },
    reversedSurveyPeriods () {
      if (!Array.isArray(this.surveyPeriods)) {
        return [];
      }
      return [...this.surveyPeriods].reverse();
    },
  },
  watch: {
    selectedPeriodEdit: 'checkButtonDisabled',
    selectedRecurrence (val) {
      if (val.status === true) {
        val.status = 1;
      }
      if (val.status === false) {
        val.status = 0;
      }
    },
  },
  async mounted () {
    await this.getSurveyRecurrencePeriod();
    await this.getMentalHealthSurveyTemplates();
    await this.getVerifiersForMultipleDomains();
    await this.getPossibleDevices();
    this.title = this.patientActiveTreatmentPlan.tpName;
    this.description = this.patientActiveTreatmentPlan.tpDescription;
    },
  methods: {
    resetFlag () {
      if (this.clickedEdit === true) {
        this.clickedEdit = false;
      }
      // this.clickedEdit = !this.clickedEdit;
    },
    getPatientDevices () {
      this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then(res => {
        this.loadingMeasurements = false;
      });
    },
    handleTitleChange () {
      this.titleChanges = true;
      this.handleChangeTitleDescription();
    },
    handleDescriptionChange () {
      this.descriptionChanges = true;
      this.handleChangeTitleDescription();
    },
    async getVerifiersForMultipleDomains () {
      const header = { domainsList: 'MEDICAL_DEVICE_MANUFACTURER' };
      await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
    },
    async getPossibleDevices () {
      this.loadingDevices = true;
      await this.$store.dispatch('medicaldevice/getPossibleDevices').then(res => {
        this.loadingDevices = false;
      });
    },
    async saveTimers (val) {
      const body = {
        time1: val.monitoringDetails.time1,
        time2: val.monitoringDetails.time2,
        time3: val.monitoringDetails.time3,
        time4: val.monitoringDetails.time4,
        measurementPeriod: val.monitoringDetails.measurementPeriod,
        measurementsCount: val.monitoringDetails.measurementsCount,
        deviceType: val.deviceType,
        id: val.monitoringDetails.id,
        treatmentPlanid: val.monitoringDetails.treatmentPlanId,
        manufacturerType: val.manufacturerId,
      };
       await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
      // });
    },
    getDeviceIcon (deviceType) {
      switch (deviceType) {
        case 1:
          return this.thermometer;
        case 2:
          return this.oximeter;
        case 3:
          return this.bloodPressure;
        case 5:
          return this.weightScale;
        case 6:
          return this.glucoseMeter;
        case 7:
          return this.cholesterolMeter;
      }
    },
    cancelEditing () {
      this.clickedEdit = false;
    },
    async getSurveys () {
      const headers = {
        patientId: this.detailedUser.userData.id,
        filterCase: this.$cookies.get('datafilter'),
        limitForOccurrences: 50,
      };
      await this.$store.dispatch('mentalhealth/getSurveyReportForPatient', headers);
    },
    disableSurvey (recurrence) {
      const body = {
        id: recurrence.id,
        StartDate: recurrence.startDate,
        EndDate: recurrence.endDate,
        templateName: recurrence.templateName,
        templateDescription: recurrence.templateDescription,
        recurrenceRuleForSurvey: recurrence.recurrenceRuleForSurvey,
        status: recurrence.status === false ? 0 : 1,
      };

      this.$store.dispatch('mentalhealth/updateSurvey', body).then(res => {
        if (res.resFlag) {
          this.selectedRecurrence = recurrence;
          showSuccessAlert('Assessment Updated ! ');
        } else {
          showSuccessAlert(res.msg);
        }
      });
      // this.clickedEdit = true;
      this.selectedPeriodEdit = null;
      this.date2 = null;
    },
    checkButtonDisabled () {
      this.buttonDisabled = !this.selectedPeriodEdit;
    },
    closeMenu (recurrence) {
      recurrence.menuOpen = false;
    },
    populateForm (recurrence) {
      this.formChangeTemplate = recurrence.templateName;
      this.formChangeDescription = recurrence.templateDescription;
    },
    updateSurvey (item) {
      let startDate = null;
      let endDate = null;

      if (Array.isArray(this.date2)) {
        startDate = this.date2[0];
        endDate = this.date2[1] ? this.date2[1] : null;
      } else {
        startDate = this.date2;
      }
      const body = {
        id: item.id,
        StartDate: startDate,
        EndDate: endDate,
        templateName: item.templateName,
        templateDescription: item.templateDescription,
        recurrenceRuleForSurvey: this.selectedPeriodEdit,
        status: 1,
      };

      this.$store.dispatch('mentalhealth/updateSurvey', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert('Assessment Updated!');
        } else {
          showSuccessAlert(res.msg);
        }
      });
      this.clickedEdit = false;
      this.selectedPeriodEdit = null;
      this.date2 = null;
      this.getSurveys();
    },
    userEditing (item) {
      this.clickedEdit = true;
      this.selectedPeriodEdit = item.recurrenceRuleForSurvey;
      this.date2 = item.startDate;
      this.selectedCardIndex = item.id;
      this.populateForm(item);
      this.openWellness = false;
    },
    async deleteSurveyById (item) {
      const body = {
        id: item.id,
      };
      await this.$store.dispatch('mentalhealth/deleteSurveyById', body.id).then(res => {
        showSuccessAlert('Survey is deleted!');
      });
      await this.getMentalHealthSurveyTemplates();
    },
    async handleChangeTitleDescription () {
      if (this.titleChanges || this.descriptionChanges) {
        const body = {
          treatmentPlanId: this.patientActiveTreatmentPlan.id,
          tpName: this.patientActiveTreatmentPlan.tpName,
          tpDescription: this.patientActiveTreatmentPlan.tpDescription,
        };
        await this.$store.dispatch('treatmentplan/updateTreatmentPlan', body);
      }

      // this.closeDrawer();
    },
    async save () {
      await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
      this.closeDrawer();
    },
    async addingDevice (val) {
      this.loadingMeasurements = true;
      const body = {
        deviceName: val.deviceName,
        deviceType: val.deviceType,
        hubIdentifier: this.hubIdentifier,
        manufacturerId: val.manufacturerId,
        manufacturerName: val.manufacturerName,
      };
      await this.$store.dispatch('medicaldevice/createMedicalDevice', body).then(async () => {
        await this.assignToTreatmentPlan(val);
      });
    },
    async assignSurveyTemplatetoPatient () {
      let startDate = null;
      let endDate = null;

      if (Array.isArray(this.date)) {
        startDate = this.date[0];
        endDate = this.date[1] ? this.date[1] : null;
      } else {
        startDate = this.date;
      }

      const body = {
        patientUserId: this.detailedUser.userData.id,
        surveyTemplateId: this.selectedTemplate,
        StartDate: startDate,
        EndDate: endDate,
        recurrenceRuleForSurvey: this.selectedPeriod,
        hospitalId: this.auth.hospitalAssignedData.id,
      };
      await this.$store.dispatch('mentalhealth/assignSurveyTemplatetoPatient', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert(res.msg);
        } else {
          showErrorAlert('Survey not created!');
        }
      });
      this.date = null;
      this.selectedTemplate = null;
      this.selectedPeriod = null;
      await this.getSurveys();
      this.openWellness = false;

      // this.closeDrawer();
    },
    async getSurveyRecurrencePeriod () {
      await this.$store.dispatch('verifiers/getSurveyRecurrencePeriod');
    },
    async getMentalHealthSurveyTemplates () {
      const body = {
        hospitalId: this.auth.hospitalAssignedData.id,
      };
      await this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', body.hospitalId);
    },
    async openWellnessCard () {
      this.openWellness = !this.openWellness;
      await this.getSurveyRecurrencePeriod();
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.openWellness = false;
    },
    async assignToTreatmentPlan (val) {
      var body = {
        treatmentPlanId: this.patientActiveTreatmentPlan.id,
        deviceType: val.deviceType,
        measurementPeriod: 'D',
        measurementsCount: 1,
        time1: '08:00',
        manufacturerType: val.manufacturerId,
      };
      await this.$store.dispatch('treatmentplan/assignDevice', body).then(() => {
        // this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then(() => {
        //   this.loadingMeasurements = false;
        // });
        this.getPatientDevices();
      });
      await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
      this.loadingMeasurements = false;
    },
    async deAssignFromTreatmentPlan (val) {
      this.loadingMeasurements = true;
      await this.$store.dispatch('treatmentplan/deassignDevice', val.monitoringDetails.id).then((res) => {
         // this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id);
        this.getPatientDevices();
     });
      await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
    },
    async deleteMedicalDevice (val) {
      var body = {
        id: val.id,
        patientId: this.detailedUser.userData.id,
      };
      try {
        await this.$store.dispatch('medicaldevice/deleteMedicalDevice', body).then(async res => {
          if (res.resFlag) {
                await this.deAssignFromTreatmentPlan(val);
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          showErrorAlert(this.$t('device-has-registered-measurements'));
          // this.$store.dispatch('alerts/clearWithDelay', this.delay);
        }
      }
    },
    // SAVE
    async saveParameters (deviceData) {
      this.saveTimers(deviceData);
      this.saveDeafaultsValue(deviceData);
    },
    async saveDeafaultsValue (deviceData) {
      // this.assignDevices.forEach((device, index) => {
          const body = {
            deviceDefaultValuesJson: this.convertForStore(deviceData),
            patientId: this.detailedUser.userData.id,
            deviceIdentifier: deviceData.deviceIdentifier,
          };

          //   if (device.deviceType === deviceData.deviceType) {
          //     const body = {
          //       deviceDefaultValuesJson: this.convertForStore(deviceData),
          //       patientId: this.detailedUser.userData.id,
          //       deviceIdentifier: device.deviceIdentifier,
          //     };
          //     this.assignDevices[index].deviceDefaultValuesJson = this.convertedValueV2(deviceData);
          this.$store.dispatch('medicaldevice/updateDeviceDefaultValuesJsonForMedicalDevice', body).then(response => {
            this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
          });
      //   }
      // });
    },
    stringFormater (val) {
      return this.$t(val);
    },
    unitFormater (val, item) {
      var units = '';
      switch (item.deviceType) {
        case 1:
          units = this.userSettings.Units.toLowerCase() === 'metric' ? '(°C)' : '(°F)';
          break;
        case 2:
          if (val.toLowerCase() === 'spo2') {
            units = '(%)';
          }
          break;
        case 3:
          if (val === 'SystolicMin') {
            units = '(mmHg)';
          }
          if (val === 'SystolicMax') {
            units = '(mmHg)';
          }
          if (val === 'SystolicMin') {
            units = '(mmHg)';
          }
          if (val === 'DiastolicMax') {
            units = '(mmHg)';
          }
          if (val === 'DiastolicMin') {
            units = '(mmHg)';
          }
          break;
        case 6:
          units = this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? '(' + this.$t('glucose-mg-dLV2') + ')' : '(' + this.$t('glucose-mmol-LV2') + ')';
          break;
        case 7:
          units = this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? '(' + this.$t('glucose-mg-dLV2') + ')' : '(' + this.$t('glucose-mmol-LV2') + ')';
          break;
        default:
          break;
      }

      return units;
    },
    convertForStore (item) {
      function parseNumbers (obj) {
        for (const key in obj) {
          if (!isNaN(obj[key])) {
            obj[key] = parseFloat(obj[key]);
          }
        }
        return obj;
      }
      if (item.deviceType === 3) {
        var deviceData = item.deviceDefaultValuesJson;
        deviceData.SystolicMin = parseFloat(deviceData.SystolicMin).toFixed(0);
        deviceData.SystolicMax = parseFloat(deviceData.SystolicMax).toFixed(0);
        deviceData.HeartBeatMin = parseFloat(deviceData.HeartBeatMin).toFixed(0);
        deviceData.HeartBeatMax = parseFloat(deviceData.HeartBeatMax).toFixed(0);
        deviceData.DiastolicMin = parseFloat(deviceData.DiastolicMin).toFixed(0);
        deviceData.DiastolicMax = parseFloat(deviceData.DiastolicMax).toFixed(0);
        return JSON.stringify(parseNumbers(deviceData));
      }
      if (item.deviceType === 1) {
        var temp = item.deviceDefaultValuesJson;
        if (this.userSettings.Units.toLowerCase() === 'metric') {
          temp.MinTemperature = parseFloat(temp.MinTemperature).toFixed(1);
          temp.MaxTemperature = parseFloat(temp.MaxTemperature).toFixed(1);
          return JSON.stringify(temp);
        }
        temp.MinTemperature = fromTo(parseFloat(temp.MinTemperature), 'F', 'C').toFixed(1);
        temp.MaxTemperature = fromTo(parseFloat(temp.MaxTemperature), 'F', 'C').toFixed(1);
        return JSON.stringify(temp);
      }

      if (item.deviceType === 6) {
        var gluco = item.deviceDefaultValuesJson;
        if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
          return JSON.stringify(parseNumbers(gluco));
        }
        gluco.BloodSugarLevelMin = (gluco.BloodSugarLevelMin / 18).toFixed(1);
        gluco.BloodSugarLevelMax = (gluco.BloodSugarLevelMax / 18).toFixed(1);
        return JSON.stringify(parseNumbers(parseNumbers(gluco)));
      }

      if (item.deviceType === 7) {
        var glucoCol = item.deviceDefaultValuesJson;
        if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
          return JSON.stringify(parseNumbers(glucoCol));
        }
        glucoCol.BloodSugarLevelMin = (glucoCol.BloodSugarLevelMin / 18).toFixed(1);
        glucoCol.BloodSugarLevelMax = (glucoCol.BloodSugarLevelMax / 18).toFixed(1);

        glucoCol.CholesterolLevelMin = (glucoCol.CholesterolLevelMin / 38.67).toFixed(1);
        glucoCol.CholesterolLevelMax = (glucoCol.CholesterolLevelMax / 38.67).toFixed(1);
        return JSON.stringify(parseNumbers(parseNumbers(glucoCol)));
      } else {
        return JSON.stringify(parseNumbers(item.deviceDefaultValuesJson));
      }
    },
    convertedValueV2 (item) {
      if (item.deviceType === 1) {
        var temp = item.deviceDefaultValuesJson;
        if (this.userSettings.Units.toLowerCase() === 'imperial') {
          temp.MinTemperature = fromTo(parseFloat(temp.MinTemperature), 'C', 'F').toFixed(1);
          temp.MaxTemperature = fromTo(parseFloat(temp.MaxTemperature), 'C', 'F').toFixed(1);
          return temp;
        }
        return temp;
      } if (item.deviceType === 6) {
        var gluco = item.deviceDefaultValuesJson;
        if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
          return gluco;
        }
        gluco.BloodSugarLevelMin = (gluco.BloodSugarLevelMin * 18).toFixed(1);
        gluco.BloodSugarLevelMax = (gluco.BloodSugarLevelMax * 18).toFixed(1);
        return gluco;
      } if (item.deviceType === 7) {
        var glucoCol = item.deviceDefaultValuesJson;
        if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
          return glucoCol;
        }
        glucoCol.BloodSugarLevelMin = (glucoCol.BloodSugarLevelMin * 18).toFixed(1);
        glucoCol.BloodSugarLevelMax = (glucoCol.BloodSugarLevelMax * 18).toFixed(1);

        glucoCol.CholesterolLevelMin = (glucoCol.CholesterolLevelMin * 38.67).toFixed(1);
        glucoCol.CholesterolLevelMax = (glucoCol.CholesterolLevelMax * 38.67).toFixed(1);
        return glucoCol;
      } else {
        return item.deviceDefaultValuesJson;
      }
    },
    async isManual (val) {
         var body = {
            patientId: this.detailedUser.userData.id,
            val: val,
          };

      await this.$store.dispatch('medicaldevice/updateIsManualMeasurementForMedicalDevice', body).then(async () => {
        await this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id);
      });
    },
  },
};
</script>
