<template>
  <div class="mx-n2">
    <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
      <v-card class="pa-7">
        <v-card-title class="d-flex justify-center align-center">
          <v-row  dense>
            <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
            </v-col>
            <v-col cols="12">
              <span>{{ $t('deleteQuestion') }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
        <v-card-actions class="mb-2">
          <v-row dense>
            <v-col cols="12">
              <v-btn block depressed color="red" class="text-color-white"  @click="deleteUserConfirm">{{ $t('deleteField') }}</v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
        ref="table"
        class="elevation-3 border-radius7 pa-2 text-xs-center"
        :items="filteredNotes"
        :height="pageHeight(88)"
        dense
        :headers="headers"
        :no-data-text="$t('no-data-available')"
        :loading="loading"
        group-by="medicalNoteData.id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props= "getDataTableFooterProps('NotesTable')"
        :items-per-page="-1"
    >
      <template v-slot:top>
        <div>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  dense
                  class="search-field my-2 mx-2"
                  hide-details
                  outlined
                  v-model="search"
              ></v-text-field>
            </v-col>
            <v-col cols="8" align-self="center" class="d-flex justify-end">
              <v-btn :disabled="detailedUser.userData.status !== 1" class="mr-1" color="chips" style="text-transform: capitalize; color:white;" :ripple="false" elevation="0" @click="synchronizeDrawers('addNote')">+ {{$t('note')}} </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:[`group.header`]="{ group, headers, items, isOpen, toggle }">
        <td @click="toggle" class="pa-1 text-left medical-teams-data-table pointer" :colspan="7" style="height:8px !important;">
          <v-row dense class="my-n1">
            <v-col>
              <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                <v-icon x-small v-if="isOpen">mdi-arrow-down</v-icon>
                <v-icon v-else x-small>mdi-arrow-right</v-icon>
              </v-btn>
              <span class="font-weight-bold font-size12">{{ items[0].medicalNoteData.medicalNoteName }}</span>
            </v-col>
            <v-col class="d-flex justify-end align-center">
              <span class="font-weight-bold font-size11 d-flex justify-end my-1 mx-2">{{formatDateUsingLocalesFromUTC(items[0].medicalNoteData.timeAdded) }}</span>
              <v-icon :disabled="!checkIfiCanDeleteMedicalNote(items[0].medicalNoteData) || detailedUser.userData.status !== 1" @click.stop="deleteMedicalNote(items[0].medicalNoteData)" small color="primary">mdi mdi-delete</v-icon>
              <v-icon :disabled="!checkIfiCanAddAdditionalNote(items[0].medicalNoteData) || detailedUser.userData.status !== 1" size="17" color="primary pointer" @click.stop="addAditionalNoteDetail('addAditionalNote', items[0])">mdi-plus</v-icon>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="secondary header-cell medical-staff" style=" height:5px !important;">
            <span class="font-weight-medium font-size12">{{ noteHeaders[0].text }}</span>
          </td>
          <td class="secondary header-cell note-detail" style=" height:5px !important;">
            <span class="font-weight-medium font-size12">{{ noteHeaders[1].text }}</span>
          </td>
          <td class="secondary header-cell note-detail" style=" height:5px !important;">
            <span class=" font-weight-medium font-size12">{{ noteHeaders[2].text }}</span>
          </td>
        </tr>
        <tr v-for="(detail) in item.medicalNoteData.medicalNoteDetails" :key="detail.id">
          <td class="text-left pl-4 font-weight-bold color-black font-size12 medical-staff-cell" style=" height:5px !important;">
            {{ medicalStaffName(detail.medicalStaffId) }}
          </td>
          <td class="text-left pl-4 color-black font-size12 note-detail-cell" style=" height:5px !important;">
            <div style="max-height:200px; max-width:600px;" class="overflow-auto">
              <span class="font-weight-bold font-size12" v-html="formatContent(detail.medicalNoteContent)"></span>
            </div>

          </td>
          <td class="text-left color-black font-weight-bold font-size11 actions-detail-cell" style=" height:5px !important;">
            <div class="d-flex align-center justify-end">
              <v-menu
                  left
                  bottom
                  class="py-0"
                  offset-y
                  transition="slide-x-transition"
              >
                <template v-slot:activator="{on, attrs}">
                    <v-icon class="px-0 mx-0"
                          v-bind="attrs"
                            color="black"
                          v-on="on"
                    >
                      mdi-dots-horizontal
                    </v-icon>
                </template >
                <v-list class="px-0" dense >
                  <v-list-item :disabled="!checkIfiCanEditMedicalNoteDetail(item.medicalNoteData, detail)" class="py-0 my-0" link dense @click="handleActionEdit(detail)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon :disabled="!checkIfiCanEditMedicalNoteDetail(item.medicalNoteData, detail)" class="icon-img mdi-18px" color="black">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14">{{$t("edit")}}</v-list-item-content>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item :disabled="!checkIfiCanDeleteAdditionalNote(detail)" link dense @click="handleActionDelete(detail)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon :disabled="!checkIfiCanDeleteAdditionalNote(detail)" color="red">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatterFilter } from '@/utils/luxon-formater';
import { getDataTableFooterProps, showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
	components: {
	},
	data () {
		return {

      dialogDelete: false,
      noteHeaders: [
        { text: 'Medical Staff', sortable: false, class: 'color_header' },
        { text: 'Note Detail', sortable: false, class: 'color_header' },
        { text: 'Actions', sortable: false, class: 'color_header', align: 'end' },
        { text: '', sortable: false, class: 'color_header' },
      ],
      sortBy: 'noteTimestamp',
      sortDesc: true,
			loading: false,
			search: '',
      headers: [
        { text: this.$t('MedicalNote'), class: 'secondary height15-black', sortable: false, align: 'start' },
        { text: '', class: 'secondary height15-black', sortable: false, align: 'end' },
        { text: this.$t('created'), class: 'secondary height15-black', sortable: false, align: 'end' },
      ],
		};
	},
	computed: {
		...mapState({
			notesFromRepo: (state) => state.appointments.notes,
      detailedUser: (state) => state.users.detailedUser,
      userData: (state) => state.authentication.userData,
      medicalStaff: (state) => state.hospitals.medicalStaff,

    }),
		...mapGetters({
			pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
		}),
    filteredNotes () {
      const searchLowerCase = this.search.toLowerCase();
      return this.notesFromRepo.filter(note => {
        // const noteDescription = note.medicalNoteCategoryTemplate?.medicalNoteCategoryDescription.toLowerCase();
        const noteCategoryName = note.medicalNoteData.medicalNoteName.toLowerCase();
        const medicalNoteContentMatches = note.medicalNoteData.medicalNoteDetails.some(detail => {
          return detail.medicalNoteContent.toLowerCase().includes(searchLowerCase);
        });
        return (
            // noteDescription.includes(searchLowerCase) ||
            noteCategoryName.includes(searchLowerCase) ||
            medicalNoteContentMatches
        );
      });
    },
	},
	mounted () {
		this.getAllNotes();
		this.getAllMedicalStaff();
    this.toggleHeaders();
  },
	methods: {
    getDataTableFooterProps,
    checkIfiCanDeleteAdditionalNote (medicalNoteDetailData) {
      return medicalNoteDetailData.medicalStaffId === this.userData.id;
    },
    checkIfiCanEditMedicalNoteDetail (medicalNoteData, medicalNoteDetail) {
      return (medicalNoteData.allowToBeEditedFromOthers || medicalNoteDetail.medicalStaffId === this.userData.id);
    },
    checkIfiCanAddAdditionalNote (medicalNoteData) {
      return (medicalNoteData.allowToBeCommentedFromOthers || medicalNoteData.createdByMedicalStaffId === this.userData.id);
    },
    checkIfiCanDeleteMedicalNote (medicalNoteData) {
      return (medicalNoteData.createdByMedicalStaffId === this.userData.id && medicalNoteData.medicalNoteDetails.length < 2);
    },

    async deleteMedicalNote (val) {
      const id = val.id;
      try {
        const res = await this.$store.dispatch('appointments/deleteMedicalNote', id);
        if (res) {
          showSuccessAlert(this.$t('success-operation'));
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          showErrorAlert(error.response.data.msg);
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      } finally {
        this.getAllNotes();
      }
    },
    formatContent (content) {
      if (content === null || content === undefined) {
        return '';
      }
      return content.replace(/\n/g, '<br>');
    },
    async handleActionDelete (item) {
      this.dialogDelete = true;
      this.selectedItem = item;
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.selectedItem = {};
    },
    async deleteUserConfirm () {
      await this.$store.dispatch('appointments/deleteAditionalNote', this.selectedItem.id)
          .then(res => {
            if (res) {
              showSuccessAlert(this.$t('success-operation'));
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
            this.getAllNotes();
            this.closeDeleteDialog();
          });
    },
    handleActionEdit (item) {
      this.$store.commit('SET_PATIENT_DETAILS_DRAWER', 'editNote');
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
        item: item,
      });
    },
    formatDate (dateString) {
      return formatterFilter(
          dateString,
          'datetime_med',
          this.timeLocalization,
          null,
      );
    },
    formatDateUsingLocalesFromUTC (dateTime) {
      return new Date(dateTime).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const openCache = table?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, false);
          });
        }
      }
    },
    medicalStaffName (staffId) {
      if (this.medicalStaff && this.medicalStaff.length > 0) {
        const matchedStaff = this.medicalStaff.find(staff => staff.id === staffId);
        if (matchedStaff) {
          return `${matchedStaff.firstName} ${matchedStaff.lastName}`;
        }
      }
      return '';
    },

    getAllMedicalStaff () {
      this.$store.dispatch('hospitals/getRelatedMedicalStaff', this.userData.hospitalAssignedData.id);
      },
      addAditionalNoteDetail (drawer, item) {
      this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
      this.$store.commit('medicalnotecategory/SET_ADITIONAL_NOTE_ID', item);
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
      });
    },

		// deleteNote (id) {
    //   const body = {
    //     noteId: id.noteId,
    //   };
		// 	this.$store.dispatch('appointments/deleteNote', body).then(() => {
		// 		this.getAllNotes();
		// 	});
		// },
		async synchronizeDrawers (drawer, item) {
				this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
        filter: 'ALL',
        selectedDate: new Date().toISOString().slice(0, 10),
      });
		},
		async getAllNotes () {
			this.loading = true;
      const body = {
        patientId: this.$route.params.id,
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
      };
      await this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body).then(res => {
        this.loading = false;
        this.toggleHeaders();
      });
		},
	},
};
</script>
<style scoped>
.header-cell {
  text-align: left;
  padding-left: 2px;
  color: black;
}
.medical-staff {
  width: 10%;
}
.note-detail {
  width: 89%;
}
.created-detail-cell {
  width: 13%;
}
.actions-detail-cell {
  width: 1%;
}
</style>
