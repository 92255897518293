<template>
  <v-form v-model="valid">
    <v-card flat class="border-radius-overflow-none">
      <v-card-title class="card-header">
        <span class="text-color-white">{{$t('edit-invoice')}}</span>
        <v-spacer />
        <v-icon class="float-right mr-2" color="white" @click="generatePDF">mdi-cloud-download</v-icon>
        <v-icon size="20" color="white" @click="closeDrawer">mdi-window-close</v-icon>
      </v-card-title>
      <v-row class="px-6 mt-2">
        <v-col cols="12">
          <v-row dense cols="12" class="px-1 mb-2">
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-select
                  @change="organizationChanged"
                  v-model="invoice.clientId"
                  :items="medicalInstitutions"
                  item-text="institutionName"
                  item-value="id"
                  :label="$t('medicalInstitution')"
                  prepend-inner-icon="mdi-office-building mr-1"
                  dense
                  hide-details
                  outlined
                  :rules="[(rule) => !!rule || '']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense cols="12" class="px-1 mb-2">
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.invoiceYear"
                  :label="$t('invoice-year')"
                  prepend-inner-icon="mdi-hospital-building mr-1"
                  dense
                  outlined
                  hide-details
                  :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.invoiceMonth"
                  :label="$t('invoice-month')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  outlined
                  hide-details
                  :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.invoiceDate"
                  :label="$t('invoice-date')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  outlined
                  hide-details
                  :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.dueDate"
                  :label="$t('invoice-due-date')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  outlined
                  hide-details
                  :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense cols="12" class="px-1 mb-2">
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.discountPercent"
                  :label="$t('invoice-discount-percent')"
                  prepend-inner-icon="mdi-hospital-building mr-1"
                  dense
                  outlined
                  hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.taxPercent"
                  :label="$t('invoice-tax-percent')"
                  prepend-inner-icon="mdi-hospital-building mr-1"
                  dense
                  hide-details
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="unitPricePerPatient"
                  :label="$t('invoice-unit-price-per-patient')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  hide-details
                  outlined
                  :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense cols="12" class="px-1 mb-2">
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.paymentTerms"
                  :label="$t('invoice-payment-terms')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  hide-details
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense cols="12" class="px-1 mb-2">
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.paymentMethods"
                  :label="$t('invoice-payment-methods')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  hide-details
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense cols="12" class="px-1 mb-2">
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="invoice.additionalInfo"
                  :label="$t('invoice-additional-info')"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  outlined
                  hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card-actions class="fixed-card-actions">
        <v-row dense class="pa-0">
          <div style="position:absolute;left:0;bottom:70px;">
            <v-col cols="6">
              <v-btn
                  class="primary float-left mt-10"
                  :loading="loading"
                  @click="deleteInvoice"
                  x-small
              >
                <span>{{ $t("delete-invoice") }}</span>
              </v-btn>
            </v-col>
          </div>
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                outlined
                width="100%"
                style="text-transform: capitalize;"
            >
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                color="#a51717"
                width="100%"
                style="text-transform: capitalize; color:white;"
                @click="updateInvoiceForOrganization"
            >
              {{$t('save')}}
            </v-btn>
          </v-col>

        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';

import Invoice from '../../data/models/Invoice';
import { showSuccessAlert } from '@/utils/utilities';

export default {
  data () {
    var date = new Date();
    return {
      loading: false,
      valid: false,
      object: {
        id: null,
        invoiceNumber: null,
        invoiceDate: new Date().toISOString().split('T')[0],
        dueDate: new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0],
        clientId: -1,
        invoiceYear: new Date().getFullYear(),
        invoiceMonth: new Date().getMonth() + 1,
        taxPercent: 0,
        discountPercent: 0,
        unitPricePerPatient: 0,
        paymentTerms: null,
        paymentMethods: null,
        additionalInfo: null,
      },
      medicalInstitutions: [],
      unitPricePerPatient: 0,
    };
  },
  computed: {
    ...mapState({
      medicalInstitutionsFromRepo: (state) => state.medicalInstitutions.medicalInstitutions,
      invoiceReferencesForOrganization: (state) => state.invoices.invoiceReferencesForOrganization,
      generatedPdfFile: (state) => state.invoices.generatedPdfFile,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
      item: (state) => state.item,
    }),
    invoice () {
        return this.item;
    },
  },
  async mounted () {
    // check if we already have loaded verifiers dont call again the API
    if (this.verifiersFromRepo === null) {
      await this.getVerifiers();
    }
    await this.getMedicalInstitutions();
    this.organizationChanged();
  },
  methods: {
    closeDrawer () {
      this.valid = false;
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
    },
    async getVerifiers () {
      const header = { domainsList: 'INVOICE_STATUS' };
      await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
    },
    async generatePDF () {
      const invoiceData = { invoiceId: this.item?.id || -1 };
      await this.$store.dispatch('invoices/getInvoicePdf', invoiceData)
          .then(res => {
            showSuccessAlert(this.$t('pdf-downloaded'));
            const url = window.URL.createObjectURL(new Blob([this.generatedPdfFile.data]));
            const link = document.createElement('a');
            const pdfFileName = `invoice-${invoiceData.invoiceId}.pdf`;
            link.href = url;
            link.setAttribute('download', pdfFileName);
            document.body.appendChild(link);
            link.click();
          });
      // showErrorAlert(this.$t('failed'));
    },
    organizationChanged () {
      const selectedOrganizationId = this.item.clientId;
      this.getInvoiceReferencesForOrganization(selectedOrganizationId);
    },
    async getInvoiceReferencesForOrganization (organizationId) {
      this.loading = true;
      await this.$store
          .dispatch('invoices/getInvoiceReferencesForOrganization', organizationId)
          .then((resp) => {
            this.loading = false;

            if (this.invoiceReferencesForOrganization) {
              this.unitPricePerPatient = this.invoiceReferencesForOrganization.unitPrice;

              this.object.taxPercent = this.invoiceReferencesForOrganization.taxPercent;
              this.object.discountPercent = this.invoiceReferencesForOrganization.discountPercent;
              this.object.unitPricePerPatient = this.invoiceReferencesForOrganization.unitPrice;
              this.object.paymentTerms = this.invoiceReferencesForOrganization.paymentTerms;
              this.object.paymentMethods = this.invoiceReferencesForOrganization.paymentMethods;
              this.object.additionalInfo = this.invoiceReferencesForOrganization.additionalInfo;
            } else {
              this.unitPricePerPatient = 0;

              this.object.taxPercent = 0;
              this.object.discountPercent = 0;
              this.object.unitPricePerPatient = 0;
              this.object.paymentTerms = '';
              this.object.paymentMethods = '';
              this.object.additionalInfo = '';
            }
          });
    },
    async generateInvoiceForOrganization () {
      const requestBody = new Invoice();
      requestBody.invoiceDate = this.invoice.invoiceDate;
      requestBody.dueDate = this.invoice.dueDate;
      requestBody.clientId = this.invoice.clientId;
      requestBody.invoiceYear = this.invoice.invoiceYear;
      requestBody.invoiceMonth = this.invoice.invoiceMonth;
      requestBody.taxPercent = this.invoice.taxPercent;
      requestBody.discountPercent = this.invoice.discountPercent;
      requestBody.unitPricePerPatient = this.unitPricePerPatient; // this.invoice.unitPricePerPatient;
      requestBody.paymentTerms = this.invoice.paymentTerms;
      requestBody.paymentMethods = this.invoice.paymentMethods;
      requestBody.additionalInfo = this.invoice.additionalInfo;

      await this.$store
          .dispatch('invoices/generateInvoiceForOrganization', requestBody)
          .then((res) => {
            this.$emit('false', res);
            this.$store.dispatch('invoices/getInvoices').then(() => {});
            this.closeDrawer();
          });
    },
    async updateInvoiceForOrganization () {
      const invoiceId = this.invoice.id;

      const requestBody = new Invoice();
      requestBody.invoiceId = invoiceId;
      requestBody.invoiceDate = this.invoice.invoiceDate;
      requestBody.dueDate = this.invoice.dueDate;
      requestBody.clientId = this.invoice.clientId;
      requestBody.invoiceYear = this.invoice.invoiceYear;
      requestBody.invoiceMonth = this.invoice.invoiceMonth;
      requestBody.taxPercent = this.invoice.taxPercent;
      requestBody.discountPercent = this.invoice.discountPercent;
      requestBody.unitPricePerPatient = this.unitPricePerPatient; // this.invoice.unitPricePerPatient;
      requestBody.paymentTerms = this.invoice.paymentTerms;
      requestBody.paymentMethods = this.invoice.paymentMethods;
      requestBody.additionalInfo = this.invoice.additionalInfo;
      requestBody.status = this.invoice.status;

      await this.$store
          .dispatch('invoices/updateInvoiceForOrganization', requestBody)
          .then((res) => {
            // this.$emit('false', res);
            this.closeDrawer();
          });
    },
    async getMedicalInstitutions () {
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async deleteInvoice () {
      const invoiceId = this.invoice.id;
      await this.$store
          .dispatch('invoices/deleteInvoiceById', invoiceId)
          .then((res) => {
            this.$emit('false', res);
            this.$store.dispatch('invoices/getInvoices').then(() => {});
            this.closeDrawer();
          });
    },
  },
};
</script>
