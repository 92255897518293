<template>
    <v-dialog
        v-model="dialog"
        width="600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
    >
      <v-card>
        <v-card-title class="primary2 py-1 px-2 sticky-title">
          <span class="font-size16 text-color-white">{{$t('office-selector')}}</span>
          <v-spacer />
          <v-icon small color="white" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-row dense>
            <v-col cols="12">
              <Search v-model="search"></Search>
            </v-col>
            <v-col cols="12">
              <v-data-table
                  :items="userData.hospitalsAssignedData"
                  fixed-header
                  :search="search"
                  :headers="headers"
                  :footer-props= "getDataTableFooterProps('branchSelectorTable')"
                  :items-per-page="-1"
                  height="40vh"
                  class="pointer elevation-2"
              >
                <template v-slot:item="{ item }">
                  <tr @click="selectedBranch(item)">
                    <td class="text-left" style="height:10px;">
                      <h6 style="font-size:14px;" class="font-weight-medium">{{ item.hospitalName }}</h6>
                    </td>
                    <td style="height:10px;" v-if="item.id === userData.hospitalAssignedData.id">
                      <span class="mdi mdi-radiobox-marked float-right color-primary mdi-18px"></span>
                    </td>
                    <td style="height:10px;" v-else>
                      <span class="mdi mdi-radiobox-blank float-right color-primary mdi-18px"></span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import Search from '@/views/Client/vuetify-components/Search.vue';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
	props: ['dialog', 'specializations'],
	components: {
    Search,
    // BirthdatePicker,
	},
	data () {
		return {
      search: '',
			loader: null,
			loading: false,
			valid: false,
			title: null,
      headers: [
        { text: 'Name', value: 'hospitalName', sortable: false, class: 'color_header' },
        { text: '', sortable: false, class: 'color_header', align: 'end' },
      ],

    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.authentication.userData,
    }),
  },
  mounted () {
    // this.scaleAndTempUnit = this.userSettings.Units;
    // this.glucoseAndCholesterolUnit = this.userSettings.Concentration;
    // this.addressData();
    // this.language = this.$cookies.get('language');
  },
  methods: {
    getDataTableFooterProps,
    selectedBranch (item) {
      this.$store.commit('authentication/SET_SELECTED_OFFICE', item);
      // window.location.reload(true);
      this.$emit('reload');
      this.$emit('false');
    },
  },
};
</script>

<style>
/*td{*/
/*  height:10px !important;*/
/*}*/
/*tr{*/
/*  height:10px !important;*/
/*}*/
th{
  height:10px !important;
}

</style>
