<template>
	<div>
		<v-row dense>
			<v-col cols="12">
					<v-data-table
						:loading="loading"
						:headers="headers"
						:no-data-text="$t('no-data-available')"
						:loading-text="$t('loading')"
						:search="appBarSearch"
						:items="filteredUsers"
						sort-by="id"
						item-key="id"
						fixed-header
						dense
						:height="pageHeight(155)"
            :footer-props= "getDataTableFooterProps('medicalStaffTable')"
            :items-per-page="-1"
            class="medical-staff-data-table"
					>
						<template v-slot:item="{ item ,index  }" >
							<tr :class="getRowsForDataTable(index)"  @click="editDrawerDetails(item)" >
								<td class="table-font-size px-0 mx-0">
                    <v-icon class="ml-2"
                            small
                            :style="getStatusColor(item)"
                           >mdi-checkbox-blank-circle</v-icon>
										<span class="ml-1">{{ item.fullName }}</span>
								</td>
                <td class="table-font-size">
                  <div class="table-cols">
                      <span>{{ $i18n?.locale === 'en' ? item.roleData.roleNameForDisplay : item.roleData.roleNameForDisplayESP }}</span>
                    </div>
                </td>
								<td v-if="roleName === 'ORGANIZATION_ADMIN'">
									<div class="table-cols2" v-if="item.role === 'Doctor'">
										<v-chip-group  column v-if="item.doctorSpetializations">
											<v-chip  label pill x-small class="my-1 mr-1 px-1 text-color-white" color="primary" v-for="spec in item.doctorSpetializations" :key="spec.spetializationId">
												{{ $i18n?.locale === 'en' ? spec.spetializationDesc : spec.spetializationDescEsp }}
											</v-chip>
										</v-chip-group>
									</div>
								</td>
								<td v-else>
									<div class="table-cols2 overflow-auto elevation-0" style="max-height:90px;" v-if="item.role === 'Doctor'">
										<v-chip-group column v-if="item.doctorMetadataReadDto">
											<v-chip label pill  x-small class="my-1 mr-1 px-1 text-color-white" color="primary" v-for="spec in item.doctorMetadataReadDto.spetializationData" :key="spec.spetializationId">
											{{ getSpecializationDescription(spec) }}
											</v-chip>
										</v-chip-group>
									</div>
								</td>
								<td v-if="roleName === 'ORGANIZATION_ADMIN'" class="pa-0">
									<div class="table-cols2 overflow-auto elevation-0" style="max-height:70px;">
										<v-chip-group column v-if="item.hospitalsAssignedData">
											<v-chip label small class=" mr-1 px-1 mr-1 px-1 text-color-white" color="primary" v-for="assignedHospital in item.hospitalsAssignedData" :key="assignedHospital.id">
												{{ assignedHospital.hospitalName }}
											</v-chip>
										</v-chip-group>
										<span class="table-items-small d-flex align-center" style="" v-else>{{$t('remote-care')}}</span>
									</div>
								</td>
								<td v-else class=" table-font-size">
										<span v-if="hospitalData !== null">{{  hospitalData?.hospitalName }}</span>
								</td>
								<td class="table-font-size">
                    <v-icon size="15" color="primary">mdi-email</v-icon>
										<span>{{ item.email }}</span>
								</td>
								<td class="table-font-size">
										<span >{{ item.phone }}</span>
								</td>
								<td>
                  <div class="d-flex align-center justify-center">
									<v-menu
										left
										bottom
										class="py-0"
										offset-y
										transition="slide-x-transition"
									>
										<template v-slot:activator="{on, attrs}">
												<v-icon color="black" dense v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
										</template >
										<v-list class="px-0" dense >
											<v-list-item class="py-0 my-0" link dense @click="editDrawerDetails(item)">
												<v-list-item-icon class="mx-1 ">
												<v-icon class="icon-img mdi-18px pt-1" color="black">mdi-eye</v-icon>
												</v-list-item-icon>
												<v-list-item-content class="font-bold text-body-2">{{$t("view")}}</v-list-item-content>
											</v-list-item>
                      <v-list-item link dense @click="handleActionSendEmail(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon class="icon-img mdi-18px" color="black">mdi-lock-reset</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14" v-if="item.status !== 1" label @click.stop="sendActivationEmail(item, 'USER_CONFIRMATION')">{{ $t('send-activation-email') }}</v-list-item-content>
                        <v-list-item-content class="font-bold font-size14 text-left" v-else label @click.stop="sendActivationEmail(item, 'RESET_PASSWORD')">{{ $t('send-reset-password-email') }}</v-list-item-content>
                      </v-list-item>
										</v-list>
									</v-menu>
                  </div>
								</td>
							</tr>
						</template>
            <template v-slot:footer.prepend>
              <span class="font-weight-bold mx-3">{{ $t('table-legend') }}</span>
              <v-tooltip color="green" top>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <span class="font-weight-medium font-size12-5">{{ $t('active') }}</span>
                    <v-icon class="mdi-18px color-green px-2" v-on="on">mdi-checkbox-blank-circle</v-icon>
                  </div>
                </template>
                <span>{{ $t('active') }}</span>
              </v-tooltip>
              <v-tooltip color="grey-darken-2" top>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <span class="font-weight-medium font-size12-5">{{ $t('inactive') }}</span>
                    <v-icon class="mdi-18px px-2 " v-on="on">mdi-checkbox-blank-circle</v-icon>
                  </div>
                </template>
                <span>{{ $t('inactive') }}</span>
              </v-tooltip>
            </template>
					</v-data-table>
      </v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AsYouType } from 'libphonenumber-js';
import { getDataTableFooterProps, getRowsForDataTable } from '@/utils/utilities';
export default {
	data () {
		return {
      expanded: [],
			loading: true,
		};
	},
	async mounted () {
		await this.getStaff();
	},
	computed: {
		...mapState({
			relatedUsers: (state) => state.hospitals.users,
			userData: (state) => state.authentication.userData,
			relatedOrganizationMedicalStaff: (state) => state.medicalInstitutions.relatedOrganizationMedicalStaff,
			hospitalData: (state) => state.hospitals.hospitalData,
		}),
		...mapGetters({
			hospitalId: 'authentication/getHospitalId',
			pageHeight: 'pageHeight',
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
			appBarSearch: 'filterbar/getSearch',
			appBarRole: 'filterbar/getRole',
			appBarSpecialization: 'filterbar/getSpecialization',
			medicalStaff: 'hospitals/getMedicalStaff',
      // appBarMedicalStaffStatus: 'filterbar/getMedicalStaffStatus',
      appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
		}),

    currentLocale () {
      return this.$i18n.locale === 'en' ? 'spetializationDesc' : 'spetializationDescEsp';
    },
		editDetailsDrawer () {
			return this.$store.state.viewDetailsDrawer;
		},
		headers () {
      let headers = [];
        headers = [
				{ text: this.$t('name'), value: 'fullName', class: 'color-header', width: 200 },
				{ text: this.$t('role'), value: 'role', class: 'color-header' },
				{ text: this.$t('specialization'), class: 'color-header', width: 200 },
				{ text: this.$t('offices'), class: 'color-header', width: 200 },
				{ text: this.$t('email'), value: 'email', class: 'color-header' },
				{ text: this.$t('phone'), value: 'phone', class: 'color-header' },
				{ text: this.$t('actions'), value: 'status', class: 'color-header', align: 'center', sortable: false, width: 50 },
				{ text: '', value: '', class: 'color-header', sortable: false },
			];
      headers.pop();
      return headers;
		},
    filteredUsers () {
      if (this.roleName === 'ORGANIZATION_ADMIN') {
        let calculateFileteredStaff = this.relatedOrganizationMedicalStaff?.filter((ru) => {
          ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
          ru.fullName = ru.firstName.concat(' ', ru.lastName);
          ru.phone = new AsYouType().input(ru.phone || '');
          ru.specializations = ru?.doctorSpetializations.map(item => item.spetializationId) ?? [];
          switch (ru.roleId) {
            case 3:
              ru.role = 'Doctor';
              break;
            case 4:
              ru.role = 'Nurse';
              break;
            case 7:
              ru.role = 'Caregiver';
              break;
            default:
              break;
          }
          return ru;
        }) || []; // Default to an empty array if undefined

        if (this.appBarRole !== -99 && this.appBarRole !== null) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.roleId === this.appBarRole);
        }

        if (this.appBarSpecialization !== -1 && this.appBarSpecialization !== null) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.specializations.includes(this.appBarSpecialization));
        }
        if (this.appBarUserStatusVerifier === -1 || this.appBarUserStatusVerifier === 1 || this.appBarUserStatusVerifier === 0) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.status === this.appBarUserStatusVerifier);
        } else if (this.appBarUserStatusVerifier === null && this.appBarUserStatusVerifier === -99) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.status === this.appBarUserStatusVerifier);
        }

        return calculateFileteredStaff;
      } else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
        let calculateFileteredStaff = this.medicalStaff?.filter((ru) => {
          if (ru.roleId === 3 || ru.roleId === 4 || ru.roleId === 7) {
            ru.statusDescription = ru.status === 1 ? 'Active' : 'Inactive';
            ru.fullName = ru.firstName.concat(' ', ru.lastName);
            ru.phone = new AsYouType().input(ru?.phone || '');
            ru.specializations = ru?.doctorMetadataReadDto?.spetializationData.map(item => item.spetializationId) ?? [];
            switch (ru.roleId) {
              case 3:
                ru.role = 'Doctor';
                break;
              case 4:
                ru.role = 'Nurse';
                break;
              case 7:
                ru.role = 'Caregiver';
                break;
              default:
                break;
            }
            return ru;
          }
        }) || []; // Default to an empty array if undefined

        // Filter the result
        if (this.appBarRole !== -99 && this.appBarRole !== null) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.roleId === this.appBarRole);
        }

        if (this.appBarSpecialization !== -1 && this.appBarSpecialization !== null) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.specializations.includes(this.appBarSpecialization));
        }
        if (this.appBarUserStatusVerifier === -1 || this.appBarUserStatusVerifier === 1 || this.appBarUserStatusVerifier === 0) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.status === this.appBarUserStatusVerifier);
        } else if (this.appBarUserStatusVerifier === null && this.appBarUserStatusVerifier === -99) {
          calculateFileteredStaff = calculateFileteredStaff.filter(u => u.status === this.appBarUserStatusVerifier);
        }

        return calculateFileteredStaff;
      } else {
        return [];
      }
    },
	},
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
     this.$store.dispatch('filterbar/resetSearch');
  },
  methods: {
    getRowsForDataTable,
    getOddRows (index) {
      if (index % 2 === 1) {
        return 'odd-row';
      }
      if (index % 2 === 0) {
        return 'even-row';
      }
    },
    getStatusColor (item) {
      if (item.status === 1) {
        return { color: '#09CB09' };
      } else {
        return {};
      }
    },
    getDataTableFooterProps,
    handleActionSendEmail (item) {
        this.sendActivationEmail(item);
    },
		getSpecializationDescription (spec) {
			return spec[this.currentLocale];
		},
		async editDrawerDetails (item) {
			this.loading = true;
			if (!this.editDetailsDrawer) {
				const headersData = { UserId: item.id };
				await this.$store.dispatch('users/getUserByIdMinimal', headersData);

				this.$store.commit('SET_EDIT_DETAILS_DRAWER', item);
        this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
			} else {
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', item);
        this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
			}
			this.loading = false;
		},
		async expand (item) {
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
		async getStaff () {
			this.loading = true;
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const organizationId = this.userData.organizationId;
				await this.$store
					.dispatch('medicalInstitutions/getRelatedOrganizationMedicalStaff', organizationId)
					.then(() => {
						this.loading = false;
					});
			} else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				const hospitalId = this.userData.hospitalAssignedData.id;
				await this.$store
					.dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
					.then(() => {
						this.loading = false;
					});
			}
		},
		closeDialog () {
			this.getStaff();
		},
		success (res) {
			this.getStaff();
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					message: res.msg,
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					message: res.msg,
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		async sendActivationEmail (item, validationType) {
            if (validationType === null || validationType === undefined) {
                validationType = 'RESET_PASSWORD';
            }
			const sendValidationEmail = { email: item.email, validationType: validationType };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
	},
};
</script>
