<template>
<div>
  <v-row>
    <v-card class="my-3 mx-1 mr-3 ml-2 elevation-1"  width="100%" color="primary2">
      <v-btn @click="toggleFullScreen('fullscreen')" class="my-n2 py-5 mx-n4" icon color="white"  absolute right>
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <span class="d-flex justify-center font-size13 font-weight-bold mt-1 white-color">{{$t('time-tracking')}}</span>
    </v-card>
    <v-data-table
        v-if="!expandClicked || fullScreen"
        class="mx-1 full-width medical-staff-data-table elevation-1"
        :items="items"
        :height="expandClicked ? pageHeight(75) : pageHeight(-320)"
        :headers="headers"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        fixed-header
        :footer-props= "getDataTableFooterProps('TimetrackingReportsTable')"
        :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left font-size12-5" style="height:10px !important;">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on }">
                <v-icon @click="toggleFullScreen('api', item)" v-on="on" small color="blue" class="mdi mdi-information-outline"></v-icon>
              </template>
              <span>{{$t('show-more')}}</span>
            </v-tooltip>
            {{ item.staffFullname }}
          </td>
          <td class="text-left font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(timetracking, index) in item.timeTrackings" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.timeTrackings.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ timetracking.patientFullName}}
            </div>
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(timetracking, index) in item.timeTrackings" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.timeTrackings.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ formatDate(timetracking.startTimeUTC)}}
            </div>
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(timetracking, index) in item.timeTrackings" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.timeTrackings.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ formatDate(timetracking.endTimeUTC)}}
            </div>
          </td>
          <td class="text-left font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(timetracking, index) in item.timeTrackings" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.timeTrackings.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ convertSeconds(timetracking.durationInSecs)}}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <span class="font-size12-5 pa-1 mt-n3 mx-auto" v-if="expandClicked && expandedItems.length === 0">{{ $t('no-data-available') }}</span>

    <v-data-table
        v-if="expandClicked && expandedItems.length !== 0"
        :loading="loading"
        class="mx-1 full-width medical-staff-data-table elevation-1"
        :items="expandedItems"
        :height="expandClicked ? pageHeight(75) : pageHeight(-300)"
        :headers="headers"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        fixed-header
        :footer-props= "getDataTableFooterProps('TimetrackingReportsTable')"
        :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left font-size12-5" style="height:10px !important;">
            {{ medicalStaff }}
          </td>
          <td class="text-left font-size12-5 px-0" style="height:10px !important;">
              {{ item.patientFullName}}
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
              {{ formatDate(item.startTimeUTC)}}
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
              {{ formatDate(item.endTimeUTC)}}
          </td>
          <td class="text-left font-size12-5 px-0" style="height:10px !important;">
              {{ convertSeconds(item.durationInSecs)}}
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-row>
</div>
</template>

<script>
import { formatterFilter } from '@/utils/luxon-formater';
import { mapGetters } from 'vuex';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
  props: ['items'],
  data () {
    return {
      medicalStaff: '',
      loading: false,
      fullScreen: false,
      expandedItems: [],

      expandClicked: false,
      selectedDateForReports: new Date().toISOString().substr(0, 10),
      headers: [
        { text: this.$t('staff'), sortable: false, class: 'secondary px-3', align: 'start' },
        { text: this.$t('patient-name'), sortable: false, class: 'secondary px-0', align: 'start' },
        { text: this.$t('start-time'), sortable: false, class: 'secondary px-1', align: 'center' },
        { text: this.$t('end-time'), sortable: false, class: 'secondary px-1', align: 'center' },
        { text: this.$t('duration'), sortable: false, class: 'secondary px-0', align: 'start' },
      ],
    };
  },
  computed: {
    ...mapGetters({ pageHeight: 'pageHeight', appBarBillingDate: 'filterbar/getBillingDate' }),
  },

  methods: {
    getDataTableFooterProps,
    async getauditreportdatatimetrackingsall (item) {
      this.medicalStaff = item.staffFullname;
      this.loading = true;
      let date = null;
      if (this.appBarBillingDate === null) {
        date = this.selectedDateForReports;
      } else {
        date = this.appBarBillingDate;
      }

      let year, month, day;
      if (this.appBarBillingDate || this.selectedDateForReports) {
        const selectedDate = date.split('-');
        if (selectedDate !== null && selectedDate !== undefined) {
          year = parseInt(selectedDate[0]);
          month = parseInt(selectedDate[1]);
          day = parseInt(selectedDate[2]);
        }
      } else {
        const currentDate = new Date();
        year = currentDate.getFullYear();
        month = currentDate.getMonth() + 1;
        day = currentDate.getDate();
      }

      const body = {
        hospitalId: item.officeId,
        staffId: item.staffId,
        year: year,
        month: month,
        day: day,
      };
      await this.$store.dispatch('reportsRepo/getauditreportdatatimetrackingsall', body).then(res => {
        this.expandedItems = res.data;
        this.loading = false;
        // TODO - HANDLE RESPONSE!
      });
    },
    convertSeconds (seconds) {
      if (seconds === 0) {
        return '0 sec';
      }

      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;

      var interval = '';
      if (hours > 0) {
        interval += hours + ' h ';
      }
      if (minutes > 0) {
        interval += minutes + ' min ';
      }
      if (remainingSeconds > 0) {
        interval += remainingSeconds + ' sec';
      }

      return interval.trim();
    },
    formatDate (dateString) {
      if (dateString === null) {
        return 'N/A';
      }
      return formatterFilter(
          dateString,
          'time_with_seconds',
          this.timeLocalization,
          null,
      );
    },
    toggleFullScreen (val, item) {
      if (val === 'fullscreen') {
        this.$emit('toggle-full-screen');
        this.expandClicked = !this.expandClicked;
        this.expandedItems = [];
        this.fullScreen = true;
      } else {
        this.$emit('toggle-full-screen');
        this.expandClicked = !this.expandClicked;
        this.fullScreen = false;
        this.getauditreportdatatimetrackingsall(item);
      }
    },
  },
};
</script>

<style scoped>

</style>
