<template>
<div class="overflow-y-hidden">
  <v-data-table
      :items="reportData"
      :height="pageHeight(220)"
      :headers="headers"
      :loading="loading"
      :no-data-text="$t('no-data-available')"
      :loading-text="$t('loading')"
      :footer-props= "getDataTableFooterProps('dailyReportsTable')"
      hide-default-footer
      :items-per-page="-1"
      fixed-header
      group-by="officeName"
      ref="table"
      :single-expand="true"
  >
    <!-- Custom group header slot -->
    <template v-slot:[`group.header`]="{ group, headers, items, isOpen, toggle }">
        <td @click="toggle" class="text-left medical-teams-data-table overflow-x-hidden overflow-y-hidden pointer elevation-1 " style="height:20px;">
          <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
            <v-icon x-small v-if="isOpen">mdi-arrow-down</v-icon>
            <v-icon v-else x-small>mdi-arrow-right</v-icon>
          </v-btn>
          <span class="font-weight-medium font-size12-5">{{ group }}</span>
        </td>
    </template>

    <!-- Custom item slot for displaying measurements and time tracking -->
    <template v-slot:item="{ item }">
      <div class="overflow-x-hidden overflow-y-hidden pa-2">
        <v-row v-if="!measurementsFullScreen">
          <v-col v-if="!measurementsFullScreen && !timeTrackingFullScreen" :cols="loginsFullScreen ? 12 : 6">
            <LoginReports
                :items="item.items"
                @toggle-full-screen="toggleFullScreen('logins')"
            >
            </LoginReports>
          </v-col>
          <v-col v-if="!measurementsFullScreen && !loginsFullScreen" :cols="timeTrackingFullScreen ? 12 : 6">
            <TimetrackingReports
                :items="item.items"
                @toggle-full-screen="toggleFullScreen('timetracking')"
            >
            </TimetrackingReports>
          </v-col>
        </v-row>
        <v-row v-if="!loginsFullScreen && !timeTrackingFullScreen" >
          <v-col :cols="measurementsFullScreen ? 12 : 12">
            <MeasurementsReports
                :items="item.items"
                @toggle-full-screen="toggleFullScreen('measurements')"

            >
            </MeasurementsReports>
          </v-col>
        </v-row>
      </div>
    </template>

<!--    <template v-slot:item="{ item }">-->
<!--      <div class="overflow-x-hidden overflow-y-hidden pa-2">-->
<!--        <v-row dense>-->
<!--          <v-row v-if="!timeTrackingFullScreen && !measurementsFullScreen">-->
<!--            <v-col :cols="loginsFullScreen ? 12 : 5">-->
<!--              <LoginReports-->
<!--                  :items="item.items"-->
<!--                  @toggle-full-screen="toggleFullScreen('logins')"-->
<!--              ></LoginReports>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row v-if="!loginsFullScreen && !measurementsFullScreen">-->
<!--            <v-col :cols="timeTrackingFullScreen ? 12 : 7">-->
<!--              <TimetrackingReports-->
<!--                  :items="item.items"-->
<!--                  @toggle-full-screen="toggleFullScreen('timetracking')"-->
<!--              ></TimetrackingReports>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row v-if="!loginsFullScreen && !timeTrackingFullScreen">-->
<!--            <v-col :cols="measurementsFullScreen ? 12 : 6">-->
<!--              <MeasurementsReports-->
<!--                  :items="item.items"-->
<!--                  @toggle-full-screen="toggleFullScreen('measurements')"-->
<!--              ></MeasurementsReports>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-row>-->
<!--      </div>-->
<!--    </template>-->

  </v-data-table>

</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MeasurementsReports from '@/views/Client/components/MeasurementsReports.vue';
import TimetrackingReports from '@/views/Client/components/TimetrackingReports';
import LoginReports from '@/views/Client/components/LoginsReports.vue';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
  components: {
    LoginReports,
    TimetrackingReports,
    MeasurementsReports,
  },
  data () {
    return {
      reportData: [],
      loading: false,
      loginsFullScreen: false,
      timeTrackingFullScreen: false,
      measurementsFullScreen: false,

      headers: [
        { text: this.$t('office-name'), sortable: false, class: 'secondary', align: 'start' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      appBarBillingDate: 'filterbar/getBillingDate',
      pageHeight: 'pageHeight',
    }),
    ...mapState({
      staffReports: (state) => state.reportsRepo.allReportsForStaff,
      loggedUser: (state) => state.authentication.userData,
    }),
  },
  watch: {
    // Watch the appBarBillingDate computed property for changes deeply
    appBarBillingDate: {
      handler (newValue) {
        this.getAllRelatedStaffAuditReport(newValue);
      },
    },
  },
  mounted () {
    this.getAllRelatedStaffAuditReport();
    this.toggleHeaders();
  },
  methods: {
    getDataTableFooterProps,
    toggleFullScreen (type) {
      if (type === 'logins') {
        this.loginsFullScreen = !this.loginsFullScreen;
      }
      if (type === 'timetracking') {
        this.timeTrackingFullScreen = !this.timeTrackingFullScreen;
      }
      if (type === 'measurements') {
        this.measurementsFullScreen = !this.measurementsFullScreen;
      }
    },

    async getAllRelatedStaffAuditReport (value) {
      this.loading = true;
      let year, month, day;
      if (value) {
        const selectedDate = value.split('-');
        if (selectedDate !== null && selectedDate !== undefined) {
          year = parseInt(selectedDate[0]);
          month = parseInt(selectedDate[1]);
          day = parseInt(selectedDate[2]);
        }
      } else {
        const currentDate = new Date();
        year = currentDate.getFullYear();
        month = currentDate.getMonth() + 1;
        day = currentDate.getDate();
      }

      const object = {
        hospitalId: this.loggedUser.hospitalAssignedData.id,
        organizationId: this.loggedUser.organizationId,
        month: month,
        year: year,
        day: day,
      };

      await this.$store.dispatch('reportsRepo/getAllRelatedStaffAuditReport', object);
        // this.toggleHeaders();
        await this.aggregateDataByOfficeId();
        this.loading = false;
    },
    async aggregateDataByOfficeId () {
      // Group data by officeId
      const groupedData = this.staffReports.reduce((acc, current) => {
        const key = current.officeId;
        if (!acc[key]) {
          acc[key] = {
            officeId: key,
            officeName: current.officeName,
            items: [],
          };
        }
        acc[key].items.push(current);
        return acc;
      }, {});

      // Convert object to array
      this.reportData = Object.values(groupedData);
    },
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const openCache = table?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, false);
          });
        }
      }
    },
  },
};
</script>
