<template>
  <v-app-bar
      class="px-0 appBar"
      id="app-bar"
      absolute
      app
      flat
      :style="{ 'border-bottom': isPatientDetailsRoute ? '1px solid #e1dce8' : 'none' }"
      :height="(isDashboardRoute || isPatientDetailsRoute || (isMappingRoute && roleIsHospitalAdmin) || (isResidentRoute && roleIsHospitalAdmin)) ? 60 : 115"
  >
      <v-row>
        <v-col class="d-flex align-center justify-start" cols="5">
          <v-btn
              small
              plain
              color="white"
              elevation="0"
              class="ml-n2"
              @click="setDrawer(!drawer)"
          >
            <img  :src="menu">
          </v-btn>
          <span class="ml-3 font-size18 font-weight-bold">{{ $t(pathname) }}</span>
        </v-col>
        <v-col cols="7" class="d-flex align-center justify-end">
          <v-menu
              offset-y
              transition="scale-transition"
              max-width="175"
              z-index="101"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="white" v-bind="attrs" v-on="on" class="elevation-0">
                <img :src="getImageForRole" class="app-bar-settings-icon mr-1">
                <span>{{ userFullName }}</span>
              </v-btn>
            </template>

            <v-list :tile="false" nav dense>
              <template>
                  <v-list-item-group v-for="(item, i) in userMenu" :key="i">
                      <v-list-item dense v-if="!item.divider" class="background-white" @click="redirect(item)">
                          <v-list-item-icon>
                              <img :src="item.icon" class="icon-img">
                          </v-list-item-icon>
                          <v-list-item-content>
                              <v-list-item-title class="d-flex align-left pl-0 pt-1">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    <v-divider v-if="item.divider" :key="'divider-' + i"></v-divider>
                  </v-list-item-group>
              </template>
            </v-list>
          </v-menu>
<!--          <ProfileDialog v-if="settingsDialog" :dialog="settingsDialog" @false="settingsDialog = false"></ProfileDialog>-->
        </v-col>
        <app-bar-filter v-if="!(isDashboardRoute || isPatientDetailsRoute)"></app-bar-filter>
      </v-row>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import {
  burgerMenu,
  settingsBlack,
  profileBlack,
  menu,
  profileSettingsBlack,
  doctorBlack,
  nurseBlack,
  settings,
  info,
  signOut,
  supportBlack,
} from '@/assets/index';
// import ProfileDialog from '../dialogs/ProfileDialog.vue';
import AppBarFilter from './AppBarFilters.vue';

export default {
	name: 'DashboardCoreAppBar',
	components: {
		// ProfileDialog,
		AppBarFilter,
	},
	data: () => ({
		burgerMenu_icon: burgerMenu,
		profile_icon: profileBlack,
		settings_icon: settingsBlack,
		chosenUnit: 'metric',
		chosenGCh: 'mmol',
		settingsDialog: false,
		profileSettingsBlack: profileSettingsBlack,
		menu: menu,
		doctorBlack: doctorBlack,
		nurseBlack: nurseBlack,
		settings: settings,
		info: info,
		signOut: signOut,
	}),
	computed: {
		...mapGetters({ roleName: 'authentication/getRole', userFullName: 'authentication/getFullName' }),
		// ...mapState(['drawer'])
		...mapState({
			drawer: state => state.drawer,
			detailedUser: (state) => state.users.detailedUser,
			authUser: (state) => state.authentication.userData,
			startTimeTrackingInfo: (state) => state.timetracking.startTimeTrackingInfo,
		}),
		isDashboardRoute () {
			return this.$route.name === 'Dashboard';
		},
		isMappingRoute () {
			return this.$route.path === '/medicalStaffMapping';
		},
		isResidentRoute () {
			return this.$route.path === '/residentMapping';
		},
    roleIsHospitalAdmin () {
      return this.roleName === 'HOSPITAL_ADMIN';
    },
		pathname () {
			const res = this.$route.name;
      return res;
		},
		showFilter () {
			return this.$route.name !== 'Patient Details';
		},
		isPatientDetailsRoute () {
			return this.$route.name === 'Patient Details';
		},
		userMenu () {
			return [
				{
					text: this.$t('about'),
					action: 'about',
					icon: info,
				},
				{
					text: this.$t('settings'),
					action: 'settings',
					icon: settings,
				},
        {
          text: this.$t('user-manual'),
          action: 'openPDF',
          icon: supportBlack,
        },
				{
					divider: true,
				},
				{
					text: this.$t('signout'),
					action: 'signout',
					icon: signOut,
				},
			];
		},
		getImageForRole () {
			switch (this.roleName) {
			case 'DOCTOR':
				return this.doctorBlack;
			case 'NURSE':
				return this.nurseBlack;
			case 'HOSPITAL_ADMIN':
				return this.profileSettingsBlack;
			case 'SYSTEM_ADMIN':
				return this.profileSettingsBlack;
			default: return this.profileSettingsBlack;
			}
		},
	},
	methods: {
		...mapMutations({
			setDrawer: 'SET_DRAWER',
		}),
    openPDF () {
      const pdfURL = 'https://www.wellnessmetric.net/wmWebManual.pdf';
      window.open(pdfURL, '_blank');
    },
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return window.innerHeight - 320;
			case 'sm': return window.innerHeight - 300;
			case 'md': return window.innerHeight - 300;
			case 'lg': return window.innerHeight - 280;
			case 'xl': return window.innerHeight - 280;
			default: return 0;
			}
		},
		selected (item) {
			localStorage.setItem('unit', item);
			switch (item) {
			case 'metric':
				this.chosenUnit = 'metric';
				break;
			case 'imperial':
				this.chosenUnit = 'imperial';
				break;
			}
		},
		signout () {
			this.$router.push({ name: 'Login' });
		},
		redirect (item) {
			switch (item.action) {
			case 'about':
				if (this.$i18n.locale === 'en') {
					window.open('https://www.wellnessmetric.net', '_blank');
				} else {
          window.open('https://www.wellnessmetric.net', '_blank');
          // TODO
          // implementing es webpage
					// window.open(`https://www.wellnessmetric.net/${this.$i18n.locale}`, '_blank');
				}
				break;
			case 'settings':
				this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
          updateDrawer: true,
					page2: 'settings',
				});
				// this.settingsDialog = true;
				break;
			case 'signout': {
				// we check for the last patient that was opened on patient details page
				// and if we have started tracking time for that patient
				// we call timetracking/stoptrackingwithpatient, before signing out
				// the id of the last patient that was opened on patient details page
				if (this.detailedUser !== null) {
					const patientId = this.detailedUser?.userData.id;
					if ([3, 4, 7].includes(this.authUser.roleData.id)) {
						const getTimeTrackingBody = {
							PatientId: patientId,
						};
						this.$store.dispatch('timetracking/getNotFinishedWithPatient', getTimeTrackingBody).then(() => {
							if (this.startTimeTrackingInfo.data.length === 0) {
								this.signout();
								return;
							}
							const stopTimeTrackingBody = {
								trackingId: this.startTimeTrackingInfo.data[0].id,
							};
							this.$store.dispatch('timetracking/stoptrackingwithpatient', stopTimeTrackingBody).then(() => {
								this.signout();
							});
						});
					} else {
						this.signout(); // for other roles just signout
					}
				} else {
					this.signout();
				}

				// this.signout();
				break;
			}
        case 'openPDF':
          this.openPDF();
          break;
			default:
				break;
			}
		},
	},
};
</script>
