<template>
  <div>
    <v-dialog v-model="dialog" width="1600">
      <v-card height="900">
        <v-card-title class="primary2 py-1 px-2 sticky-title">
          <span class="font-size16 text-color-white">{{
            $t("Download Assessment")
          }}</span>
          <v-spacer />
          <v-icon @click="downloadPDF" color="white" class="mr-2">mdi-cloud-download</v-icon>
          <v-icon small color="white" @click="dialog = false"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <div id="contentToPrint">
          <v-row>
            <v-col class="text-left"
                   cols="6"
            >
              <div
              ><span style="font-weight: bold; margin-left:10px;" class="black--text">{{
                  detailedUser.userData?.firstName +
                  " " +
                  detailedUser.userData?.lastName
                }}</span></div
              >
              <div
              ><span class="black--text" style="margin-left:10px;">{{
                  detailedUser.userData?.address
                }}</span></div
              >
              <div
              ><span class="black--text" style="margin-left:10px;">{{
                  phone(this.detailedUser.userData?.phone)
                }}</span></div
              >
              <div
              ><span class="black--text" style="margin-left:10px;">{{
                  detailedUser.userData?.email
                }}</span></div
              >
              <div
              ><span class="black--text" style="margin-left:10px;">{{
                  detailedUser?.userData?.patientMetadata
                      ?.medicalRegistrationNumber
                      ? detailedUser?.userData?.patientMetadata
                          ?.medicalRegistrationNumber
                      : ""
                }}</span></div
              >
            </v-col>

            <v-col
                cols="6"
                style="
              text-align: left;
            "
            >
              <div  style="text-align: left ;margin-left: 10px; margin-right: 4px;">
                <div style="font-weight: bold; color:black;">{{detailedUser.medicalTeamData[0]?.hospitalName}}</div>
                <!-- <v-row style="text-right: right"><span>{{hospitals[0]?.address}}</span></v-row> -->
                <div  class="black--text">{{ hospitals[0]?.address }}</div>
                <div class="black--text">{{hospitals[0]?.contactPhone}}</div>
                <div  class="black--text">{{hospitals[0]?.contactEmail}}</div>
              </div>
            </v-col>
            <!--        <v-col style="text-align: left ;margin-left: 4px; margin-right: 4px;">-->
            <!--          <v-row style="font-weight: bold; color:black;">{{detailedUser.medicalTeamData[0]?.hospitalName}}</v-row>-->
            <!--          &lt;!&ndash; <v-row style="text-right: right"><span>{{hospitals[0]?.address}}</span></v-row> &ndash;&gt;-->
            <!--          <v-row  class="black&#45;&#45;text">{{ hospitalData.address }}</v-row>-->
            <!--          <v-row class="black&#45;&#45;text">{{hospitalData.contactPhone}}</v-row>-->
            <!--          <v-row  class="black&#45;&#45;text">{{hospitalData.contactEmail}}</v-row>-->
            <!--        </v-col>-->
          </v-row>
          <v-row>
            <v-card width="2000" elevation="3">
              <v-card
                  elevation="3"
                  v-for="(question, index) in assessment?.surveyQuestionsList"
                  :key="index"
                  class="text-left mb-4"
                  style="margin-left:20px; !important;"
              >
                <div class="d-flex">
                  <v-avatar size="20" color="chips" class="ma-1">
                    <span class="text-white font-size12">{{ index + 1 }}</span>
                  </v-avatar>
                  <div>
                    <span class="font-weight-bold font-size13">{{
                        question.question
                      }}</span>
                  </div>
                </div>
                <v-row>
                  <v-col cols="10" style="display:flex !important;">
                    <div
                        style="display:flex !important;"
                        v-for="(answer, index2) in question.offeredAnswersList"
                        :key="index2"
                        class="font-size12-5 font-weight-medium ml-2 py-1 d-flex"
                    >
                      <v-chip
                          :style="answer.selectedAsAnswer ? 'border: 2px solid #a51717 !important' : ''"
                          class="mb-1"
                          style="color:black !important;"
                          small
                          :color="answer.selectedAsAnswer ? 'primary' : ''"
                      >{{ answer.answer }}</v-chip
                      >
                    </div>
                  </v-col>
                  <v-col
                      cols="2"
                      class="d-flex justify-center align-center text-center font-size14 font-weight-bold"
                  >
                    {{
                      getAnsweredQuestion(question)?.[0]?.scorePoints !==
                      undefined &&
                      getAnsweredQuestion(question)?.[0]?.scorePoints !== null
                          ? `Score: ${
                              getAnsweredQuestion(question)?.[0]?.scorePoints
                          }`
                          : "Not Answered"
                    }}
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
            <v-card
                width="100%"
                class="ml-2"
                v-if="
                selectedSurvey.selectedSurvey.surveysList[0]
                  ?.mentalHealthCategoryId === 27
              "
                elevation="0"
            >
              <PHQ9ScoreSummaryInformation :dialog="dialog"></PHQ9ScoreSummaryInformation>
            </v-card>
            <v-card
                class="ml-2"
                style="padding-top:20px; !important;"
                v-if="
                selectedSurvey.selectedSurvey.surveysList[0]
                  ?.mentalHealthCategoryId === 34
              "
                elevation="0"
            >
              <AlcoholScreeningQuestionnaire :dialog="dialog"></AlcoholScreeningQuestionnaire>
            </v-card>
            <!--            <v-card v-if=" selectedSurvey.selectedSurvey.surveysList[0]?.mentalHealthCategoryId === 35" elevation="0" >-->
            <!--              <ProdromalQuestionnaire></ProdromalQuestionnaire>-->
            <!--            </v-card>-->
            <v-card
                width="100%"
                v-if="
                selectedSurvey.selectedSurvey.surveysList[0]
                  ?.mentalHealthCategoryId === 33
              "
                elevation="0"
            >
              <MoodDisorderQuestionnaire :dialog="dialog"></MoodDisorderQuestionnaire>
            </v-card>
          </v-row>
        </div>

      </v-card>
    </v-dialog>
    <v-card
      class="elevation-0 border-radius0"
      height="150"
      style="position: sticky; top: 0; left: 0; z-index: 10000"
    >
      <div class="d-flex pa-5 pb-0">
        <v-icon
          class="mt-2 mr-4"
          style="border: 1px solid #ccc; border-radius: 3px"
          @click="closeDrawer"
          color="primary"
          >mdi mdi-chevron-left</v-icon
        >
        <span class="mt-3 font-size15 font-weight-medium">{{
          selectedSurvey.selectedSurvey.templateName
        }}</span>
      </div>
      <v-row class="mt-2">
        <v-col cols="12">
          <!--          <v-btn-->
          <!--              block-->
          <!--              class="font-size11 py-3"-->
          <!--              elevation="0"-->
          <!--              :style="{ borderRadius: '0px', width: '100%', fontSize: '13px', height: '15px', backgroundColor: index === selectedTab ? '#E1DCE8' : 'white', color: index === selectedTab ? 'black' : 'black' }"-->
          <!--              @click="selectTab(index)"-->
          <!--          >-->
          <!--            {{ btn }}-->
          <!--          </v-btn>-->
          <v-btn-toggle
            v-model="selectedTab"
            row
            active-class="chips white--text"
            mandatory
            class="white"
          >
            <v-btn
              :class="
                $vuetify.breakpoint.mdAndDown
                  ? 'tabs-class-small'
                  : 'tabs-class-big'
              "
              :value="1"
              :input-value="selectedTab"
              >{{ $t("surveyHistory") }}</v-btn
            >
            <v-btn
              :class="
                $vuetify.breakpoint.mdAndDown
                  ? 'tabs-class-small'
                  : 'tabs-class-big'
              "
              :value="0"
              :input-value="selectedTab"
              >{{ $t("progressGraphs") }}</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add your headers here -->
    <div v-if="selectedTab === 1">
      <v-expansion-panels hover accordion :height="pageHeight(-250)">
        <v-expansion-panel
          v-for="(item, index) in recurrencesList"
          :key="index"
        >
          {{ getScoresForAssessment(item) }}
          <v-expansion-panel-header hide-actions>
            <template v-slot:default="{ open }">
              <div>
                <div class="d-flex align-center">
                  <v-icon>
                    <template v-if="open">mdi-chevron-up</template>
                    <template v-else>mdi-chevron-down</template>
                  </v-icon>
                  <div>
                    <h6
                      class="font-size12"
                      :style="{ color: item.isCompleted ? 'black' : 'red' }"
                    >
                      {{ formatDate(item.submitAtDate) }}
                    </h6>
                  </div>
                  <span
                    class="font-size12 ml-4 mr-5 font-weight-bold text-center"
                    >{{ recurrencesList[0].templateName }}</span
                  >
                  <h6
                    class="font-size12 ml-auto mr-5"
                    :style="{ color: item.isCompleted ? 'black' : 'red' }"
                  >
                    {{ item.isCompleted ? "Completed" : "Not Completed" }}
                  </h6>
                  <div class="mr-2">
                    <v-icon @click.stop="openDialog(item)" color="primary">{{
                      item.isCompleted ? "mdi-cloud-download" : ""
                    }}</v-icon>
                  </div>
                </div>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="3">
              <v-card
                elevation="3"
                v-for="(question, index) in item.surveyQuestionsList"
                :key="index"
                class="text-left mb-4"
              >
                <div class="d-flex">
                  <v-avatar size="20" color="chips" class="ma-1">
                    <span class="text-white font-size12">{{ index + 1 }}</span>
                  </v-avatar>
                  <div>
                    <span class="font-weight-bold font-size13">{{
                      question.question
                    }}</span>
                  </div>
                </div>
                <v-row>
                  <v-col cols="10" class="d-flex">
                    <div
                      v-for="(answer, index2) in question.offeredAnswersList"
                      :key="index2"
                      class="font-size12-5 font-weight-medium ml-2 py-1"
                    >
                      <v-chip
                        class="mb-1"
                        small
                        :color="answer.selectedAsAnswer ? 'primary' : ''"
                        >{{ answer.answer }}</v-chip
                      >
                    </div>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-center align-center text-center font-size14 font-weight-bold"
                  >
                    {{
                      getAnsweredQuestion(question)?.[0]?.scorePoints !==
                        undefined &&
                      getAnsweredQuestion(question)?.[0]?.scorePoints !== null
                        ? `Score: ${
                            getAnsweredQuestion(question)?.[0]?.scorePoints
                          }`
                        : "Not Answered"
                    }}
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
            <v-card
              v-if="
                selectedSurvey.selectedSurvey.surveysList[0]
                  ?.mentalHealthCategoryId === 27
              "
              elevation="0"
            >
              <PHQ9ScoreSummaryInformation></PHQ9ScoreSummaryInformation>
            </v-card>
            <v-card
              v-if="
                selectedSurvey.selectedSurvey.surveysList[0]
                  ?.mentalHealthCategoryId === 34
              "
              elevation="0"
            >
              <AlcoholScreeningQuestionnaire></AlcoholScreeningQuestionnaire>
            </v-card>
            <!--            <v-card v-if=" selectedSurvey.selectedSurvey.surveysList[0]?.mentalHealthCategoryId === 35" elevation="0" >-->
            <!--              <ProdromalQuestionnaire></ProdromalQuestionnaire>-->
            <!--            </v-card>-->
            <v-card
              v-if="
                selectedSurvey.selectedSurvey.surveysList[0]
                  ?.mentalHealthCategoryId === 33
              "
              elevation="0"
            >
              <MoodDisorderQuestionnaire></MoodDisorderQuestionnaire>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- charts sr=taring here   -->
    <div class="mb-8" v-if="selectedTab === 0">
      <AssessmentsGraph30
        v-if="
          graphData !== null &&
          graphData !== undefined &&
          graphData.SurveyCategoryId === 30
        "
        ref="assessmentsGraph30"
        :graphData="graphData"
      >
      </AssessmentsGraph30>
      <AssessmentsGraph31
        v-if="
          graphData !== null &&
          graphData !== undefined &&
          graphData.SurveyCategoryId === 31
        "
        ref="assessmentsGraph31"
        :graphData="graphData"
      >
      </AssessmentsGraph31>
      <AssessmentsGraph32
        v-if="
          graphData !== null &&
          graphData !== undefined &&
          graphData.SurveyCategoryId === 32
        "
        ref="assessmentsGraph32"
        :graphData="graphData"
      >
      </AssessmentsGraph32>
      <AssessmentsGraph27
        v-if="
          graphData !== null &&
          graphData !== undefined &&
          graphData.SurveyCategoryId === 27
        "
        ref="assessmentsGraph32"
        :graphData="graphData"
      >
      </AssessmentsGraph27>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AssessmentsGraph30 from '@/views/Client/components/AssessmentsGraph30.vue';
import AssessmentsGraph31 from '@/views/Client/components/AssessmentsGraph31.vue';
import AssessmentsGraph32 from '@/views/Client/components/AssessmentsGraph32.vue';
import AssessmentsGraph27 from '@/views/Client/components/AssessmentsGraph27.vue';
import { getScoresForAssessment } from '@/utils/utilities';
import PHQ9ScoreSummaryInformation from '@/views/Client/components/PHQ-9ScoreSummaryInformation.vue';
import AlcoholScreeningQuestionnaire from '@/views/Client/components/AlcoholScreeningQuestionnaire.vue';
import MoodDisorderQuestionnaire from '@/views/Client/components/MoodDisorderQuestionnaire.vue';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import jsPDF from 'jspdf';

export default {
  props: {
    selectedSurvey: Object,
  },
  components: {
    MoodDisorderQuestionnaire,
    PHQ9ScoreSummaryInformation,
    AlcoholScreeningQuestionnaire,
    AssessmentsGraph30,
    AssessmentsGraph31,
    AssessmentsGraph32,
    AssessmentsGraph27,
  },
  data () {
    return {
      enableSurvey: 0,
      assessment: null,
      width: 5,
      selectedAssessment: null,
      hospitals: [],
      dialog: false,
      radius: 0,
      getCompletedItem: null,
      padding: 8,
      autoLineWidth: false,
      color: '#6600FF',
      buttons: [this.$t('surveyHistory'), this.$t('progressGraphs')],
      selectedTab: 1,
      icon: 'mdi-chevron-right',
      recurrencesList: [],
      graphData: null,
    };
  },
  computed: {
    ...mapState({
      surveyForPatient: (state) => state.mentalhealth.surveyPatentDetails,
      detailedUser: (state) => state.users.detailedUser,
      hospitalData: (state) => state.hospitals.hospitals,
    }),
    ...mapGetters({ pageHeight: 'pageHeight' }),
  },
  async created () {
    this.getRecurrences();
    this.generateChartData();
  },
  mounted () {
    this.getHospitals();
    },
  methods: {
    downloadPDF () {
      this.pdfColor = '#FFFFFF';
      this.showBorder = true;
      const fileName = this.assessment.templateName + '.pdf';

      // Get the element to print
      var elementHTML = document.querySelector('#contentToPrint');

      // Check if the element exists
      if (!elementHTML) {
        // console.error('Element #contentToPrint not found.');
        return;
      }

      // eslint-disable-next-line new-cap
      var doc = new jsPDF('p', 'mm', [297, 210], true);
      doc.html(elementHTML, {
        callback: function (doc) {
          doc.save(fileName);
        },
        margin: [5, 5, 5, 5],
        autoPaging: 'text',
        x: 0,
        y: 3,
        width: 200, // target width in the PDF document
        windowWidth: 900, // window width in CSS pixels
      });

      setTimeout(() => {
        this.pdfColor = '#F6F6F6';
        this.showBorder = false;
      }, 0.1);
    },
    sendItems (item) {
      this.assessment = item;
    },
    async getHospitals () {
      await this.$store.dispatch('hospitals/getHospitals').then(() => {
        if (this.hospitalData !== null) {
          this.hospitals = this.hospitalData;
        } else this.hospitals = [];
      });
    },
    phone (val) {
      if (val !== undefined) {
        if (!isValidPhoneNumber(val)) {
          this.phoneErrors = true;
        } else {
          this.phoneErrors = false;
        }
        return new AsYouType().input(val);
      }
    },
    openDialog (item) {
      this.assessment = item;
      this.dialog = true;
    },
    getScoresForAssessment,
    getAnsweredQuestion (question) {
      if (question.isAnswered === true) {
        // Filter to get answers marked as selected
        return question.offeredAnswersList.filter(
          (answer) => answer.selectedAsAnswer === true,
        );
      } else {
        return null; // Return null if the question is not answered
      }
    },
    selectTab (index) {
      this.selectedTab = index;
    },
    changeIcon () {
      this.icon =
        this.icon === 'mdi-chevron-right'
          ? 'mdi-chevron-down'
          : 'mdi-chevron-right';
    },
    generateChartData () {
      if (this.selectedSurvey.selectedSurvey === null) return;

      const surveysList = this.selectedSurvey.selectedSurvey.surveysList;

      if (surveysList.length === 0) {
        return;
      }

      const firstSurvey = surveysList[0];

      const mentalHealthCategoryId = firstSurvey.mentalHealthCategoryId;

      var question1Text = null;
      var question2Text = null;
      var question3Text = null;

      var question1SeriesData = [];
      var question2SeriesData = [];
      var question3SeriesData = [];

      if (mentalHealthCategoryId === 30) {
        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              const questionList = currentRecurrence.surveyQuestionsList;

              for (let ii = 0; ii < questionList.length; ii++) {
                const currentQuestion = questionList[ii];

                for (
                  let jj = 0;
                  jj < currentQuestion.offeredAnswersList.length;
                  jj++
                ) {
                  const currentAnswer = currentQuestion.offeredAnswersList[jj];

                  // do the calculation only for selected one as answered
                  if (currentAnswer.selectedAsAnswer === true) {
                    if (currentQuestion.orderNumber === 1) {
                      // handle Q1
                      question1Text = currentQuestion.question;
                      question1SeriesData.push([
                        currentRecurrence.submitAtDate,
                        parseInt(currentAnswer.answer),
                      ]);
                    } else if (currentQuestion.orderNumber === 2) {
                      // handle Q2
                      question2Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question2SeriesData.push([
                          currentRecurrence.submitAtDate,
                          1,
                        ]);
                      } else {
                        question2SeriesData.push([
                          currentRecurrence.submitAtDate,
                          -1,
                        ]);
                      }
                    } else if (currentQuestion.orderNumber === 3) {
                      // handle Q3
                      question3Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question3SeriesData.push([
                          currentRecurrence.submitAtDate,
                          1,
                        ]);
                      } else {
                        question3SeriesData.push([
                          currentRecurrence.submitAtDate,
                          -1,
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 30,
          Question1: {
            QuestionText: question1Text,
            Series: [
              {
                name: 'Selected answer',
                data: question1SeriesData,
              },
            ],
            PossibleAnswers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
          Question2: {
            QuestionText: question2Text,
            Series: [
              {
                name: 'Selected answer',
                data: question2SeriesData,
              },
            ],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
          },
          Question3: {
            QuestionText: question3Text,
            Series: [
              {
                name: 'Selected answer',
                data: question3SeriesData,
              },
            ],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
          },
        };
      } else if (mentalHealthCategoryId === 31) {
        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              const questionList = currentRecurrence.surveyQuestionsList;

              for (let ii = 0; ii < questionList.length; ii++) {
                const currentQuestion = questionList[ii];

                for (
                  let jj = 0;
                  jj < currentQuestion.offeredAnswersList.length;
                  jj++
                ) {
                  const currentAnswer = currentQuestion.offeredAnswersList[jj];

                  // do the calculation only for selected one as answered
                  if (currentAnswer.selectedAsAnswer === true) {
                    if (currentQuestion.orderNumber === 1) {
                      // handle Q1
                      question1Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question1SeriesData.push([
                          currentRecurrence.submitAtDate,
                          1,
                        ]);
                      } else {
                        question1SeriesData.push([
                          currentRecurrence.submitAtDate,
                          -1,
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 31,
          Question1: {
            QuestionText: question1Text,
            Series: [
              {
                name: 'Selected answer',
                data: question1SeriesData,
              },
            ],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
            xAxisCategories: [],
          },
        };
      } else if (mentalHealthCategoryId === 32) {
        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              const questionList = currentRecurrence.surveyQuestionsList;

              for (let ii = 0; ii < questionList.length; ii++) {
                const currentQuestion = questionList[ii];

                for (
                  let jj = 0;
                  jj < currentQuestion.offeredAnswersList.length;
                  jj++
                ) {
                  const currentAnswer = currentQuestion.offeredAnswersList[jj];

                  // do the calculation only for selected one as answered
                  if (currentAnswer.selectedAsAnswer === true) {
                    if (currentQuestion.orderNumber === 1) {
                      // handle Q1
                      question1Text = currentQuestion.question;
                      if (currentAnswer.answer === 'Yes') {
                        question1SeriesData.push([
                          currentRecurrence.submitAtDate,
                          1,
                        ]);
                      } else {
                        question1SeriesData.push([
                          currentRecurrence.submitAtDate,
                          -1,
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 31,
          Question1: {
            QuestionText: question1Text,
            Series: [
              {
                name: 'Selected answer',
                data: question1SeriesData,
              },
            ],
            // eslint-disable-next-line quotes
            PossibleAnswers: ["Yes", "No"],
            xAxisCategories: [],
          },
        };
      } else if (mentalHealthCategoryId === 27) {
        question1Text = this.selectedSurvey.selectedSurvey.templateName; // 'PHQ-9';
        question2Text = this.selectedSurvey.selectedSurvey.templateDescription; // 'Over the last 2 weeks, how often have you been bothered by any of the following problems?';

        for (let i = 0; i < surveysList.length; i++) {
          const currentSurvey = surveysList[i];

          for (let j = 0; j < currentSurvey.surveyRecurrencesList.length; j++) {
            const currentRecurrence = currentSurvey.surveyRecurrencesList[j];

            // check if the recurrence is completed/answered by patient
            if (currentRecurrence.isCompleted === true) {
              question1SeriesData.push([
                currentRecurrence.submitAtDate,
                parseInt(currentRecurrence.totalScorePoints),
              ]);
            }
          }
        }

        this.graphData = {
          SurveyCategoryId: 27,
          Question1: {
            QuestionText: question1Text,
            Series: [
              {
                name: 'Score',
                data: question1SeriesData,
              },
            ],
            PossibleAnswers: Array.from({ length: 28 }, (_, i) => i), // array of 0 to 54
          },
        };
      }
    },
    getRecurrences () {
      for (
        let i = 0;
        i < this.selectedSurvey.selectedSurvey.surveysList.length;
        i++
      ) {
        var currentSurvey = this.selectedSurvey.selectedSurvey.surveysList[i];

        // get the recurrences for this survey
        for (let i = 0; i < currentSurvey.surveyRecurrencesList.length; i++) {
          var currentRecurrence = currentSurvey.surveyRecurrencesList[i];
          this.recurrencesList.push(currentRecurrence);
        }
      }
    },
    closeDrawer () {
      this.$emit('close', {
        isOpen: false,
        selectedSurvey: this.selectedSurvey,
      });
    },
    formatDate (timestamp) {
      // Convert input string to a Date object
      var dateObject = new Date(timestamp);

      // Format the date as "MMM, dd, yyyy"
      var formattedDate = dateObject.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return formattedDate;
    },
  },
};
</script>

<style>
.not-completed {
  color: red;
}
#chartContainer {
  border: 1px solid #ccc;
}
#chart-title {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
#chart-container {
  position: relative;
}
.v-expansion-panel-header {
  min-height: 10px !important;
  padding: 5px !important;
}
</style>
