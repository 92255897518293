<template>
  <div class="overflow-y-hidden">
    <v-row>
      <AssessmentsDeleteDialog
          :dialog="dialog"
          :surveyId="surveyId"
          @update:dialog="dialog = $event"
          @update:surveyId="surveyId = $event"
      />
    </v-row>
    <v-data-table
      class="pa-1 mt-6 text-xs-center medical-staff-data-table"
      :headers="headers"
      :loading="loading"
      :no-data-text="$t('no-data-available')"
      :loading-text="$t('loading')"
      :headers-length="headers.length"
      :search="appBarSearch"
      :items="surveyTemplates"
      fixed-header
      dense
      :height="pageHeight(160)"
      :single-expand="true"
      ref="table"
      :footer-props="getDataTableFooterProps('AssessmentsTable')"
      :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr
          @click="editDrawerDetails(item)"
          class="wellness-assessment-data-table"
        >
          <td class="table-font-size pl-0">
            <v-icon class="ml-2 mr-1" small :style="getStatusColor(item)"
              >mdi-checkbox-blank-circle</v-icon
            >
            {{ item.templateName }}
          </td>
          <td class="table-font-size">
            {{ item.templateDescription || $t("no-description-available") }}
          </td>

          <td class="table-font-size">
            <div class="d-flex">
              <v-img :src="doctorBlack" max-width="13px" max-height="20px" />
              <span class="ml-2">
                {{ item.createdByFullname }}
              </span>
            </div>
          </td>
          <td></td>
          <td>
            <div class="d-flex justify-end align-center">
              <v-chip
                pill
                x-small
                color="primary"
                class="custom-chip-size mr-5"
                label
                >{{ item.questionsListCount }}
                {{
                  item.questionsListCount > 1 ? $t("questions") : $t("question")
                }}</v-chip
              >
              <v-menu
                left
                bottom
                class="py-0"
                offset-y
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    class="py-0 my-0"
                    link
                    dense
                    @click="editDrawerDetails(item)"
                  >
                    <v-list-item-icon class="mx-1">
                      <v-icon class="icon-img mdi-18px" color="main_black"
                        >mdi-eye</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14">{{
                      $t("view")
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    class="py-0 my-0"
                    link
                    dense
                    @click.stop="deleteSurveyById(item)"
                  >
                    <v-list-item-icon class="mx-1">
                      <v-icon class="icon-img mdi-18px" color="main_red"
                        >mdi-delete</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14">{{
                      $t("delete")
                    }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:footer.prepend>
        <span class="font-weight-bold mx-3">{{ $t("table-legend") }}</span>
        <v-tooltip color="green" top>
          <template v-slot:activator="{ on }">
            <div class="d-flex">
              <span class="font-weight-medium font-size12-5">{{
                $t("active")
              }}</span>
              <v-icon class="mdi-18px color-green px-2" v-on="on"
                >mdi-checkbox-blank-circle</v-icon
              >
            </div>
          </template>
          <span>{{ $t("active") }}</span>
        </v-tooltip>
        <v-tooltip color="grey-darken-2" top>
          <template v-slot:activator="{ on }">
            <div class="d-flex">
              <span class="font-weight-medium font-size12-5">{{
                $t("inactive")
              }}</span>
              <v-icon class="mdi-18px px-2" v-on="on"
                >mdi-checkbox-blank-circle</v-icon
              >
            </div>
          </template>
          <span>{{ $t("inactive") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { getDataTableFooterProps } from '@/utils/utilities';
import { doctorBlack } from '@/assets';
import AssessmentsDeleteDialog from '@/views/dialogsNew/AssessmentsDeleteDialog.vue';
export default {
  components: { AssessmentsDeleteDialog },
	data () {
		return {
      surveyId: '',
      dialog: false,
			loading: true,
		};
	},
	computed: {
    doctorBlack () {
      return doctorBlack;
    },
		...mapState({
			userData: (state) => state.authentication.userData,
      surveyTemplates: (state) => state.mentalhealth.surveys,
		}),
		...mapGetters({
			hospitalId: 'authentication/getHospitalId',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
		}),
		editDetailsDrawer () {
			return this.$store.state.viewDetailsDrawer;
		},
		headers () {
			return [
        { text: this.$t('assessment-name'), value: 'templateName', sortable: true, class: 'color-header pl-4', width: 300 },
				{ text: this.$t('assessment-description'), value: 'templateDescription', sortable: true, class: 'color-header', width: 600 },
        { text: this.$t('created-by'), value: 'templateName', sortable: true, class: 'color-header' },
        { text: this.$t(''), value: 'status', class: 'color-header', sortable: false, align: 'end' },
				{ text: this.$t('actions'), value: 'status', class: 'color-header pr-3', sortable: false, align: 'end' },
			];
		},
	},
  async mounted () {
		await this.loadData();
	},
	methods: {
    getStatusColor (status) {
      switch (status.status) {
        case 1:
          return 'color: #09CB09';
        default:
          return '';
      }
    },
    getDataTableFooterProps,
		async editDrawerDetails (item) {
			this.loading = true;
			if (!this.editDetailsDrawer) {
				await this.$store.dispatch('mentalhealth/getSurveysById', item.id);
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
			} else {
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
			}
			this.loading = false;
		},
		async deleteSurveyById (item) {
			this.dialog = true;
			this.surveyId = item.id;
		},
		loadData () {
			this.loading = true;
			// const hospitalId = this.userData.hospitalAssignedData.id;
      const hospital = localStorage.getItem('selectedHospitalAssignedId');
			this.$store
				.dispatch('mentalhealth/getMentalHealthSurveyTemplates', hospital).then(() => {
					this.loading = false;
				});
		},
	},
};
</script>
