<template>
  <div>
    <v-row>
      <v-card class="my-3 ml-3 mr-1 elevation-1" width="100%" color="primary2">
        <v-btn @click="toggleFullScreen('fullscreen')" class="my-n2 py-5 mx-n3" icon color="white"  absolute right>
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <span class="d-flex justify-center font-size13 font-weight-bold mt-1 white-color">{{$t('logins')}}</span>
      </v-card>
      <v-data-table
          v-if="!expandClicked || fullScreen"
          class="ml-2 full-width medical-staff-data-table elevation-1"
          :items="items"
          :height="expandClicked ? pageHeight(75) : pageHeight(-320)"
          :headers="headers"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          fixed-header
          :footer-props= "getDataTableFooterProps('loginsReportTable')"
          :items-per-page="-1"
      >
        <template v-slot:item="{ item }">
          <tr class="py-0 my-0">
            <td class="text-left font-size12-5" style="height:10px !important;">
              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on }">
                  <v-icon @click="toggleFullScreen('api', item)" v-on="on" small color="blue" class="mdi mdi-information-outline"></v-icon>
                </template>
                <span>{{$t('show-more')}}</span>
              </v-tooltip>
              {{ item.staffFullname }}
            </td>
            <td class="text-left mx-0 px-0 font-size12-5" style="height:10px !important;">
              <div v-for="(logins, index) in item.logins" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.logins.length - 1 ? '1px solid #ccc' : 'none' }">
                {{ authStatus(logins.eventOrigin) }}
              </div>
            </td>
            <td class="text-center mx-0 px-0 font-size12-5" style="height:10px !important;">
              <div v-for="(logins, index) in item.logins" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.logins.length - 1 ? '1px solid #ccc' : 'none' }">
                {{ formatDate(logins.eventTimestamp) }}
              </div>
            </td>
          </tr>

        </template>
      </v-data-table>
      <span class="font-size12-5 pa-1 mt-n3 mx-auto" v-if="expandClicked && !fullScreen && expandedItems.length === 0">{{ $t('no-data-available') }}</span>
      <v-data-table
          v-if="expandClicked && expandedItems.length !== 0"
          :loading="loading"
          class="ml-2 full-width medical-staff-data-table elevation-1"
          :items="expandedItems"
          :height="expandClicked ? pageHeight(75) : pageHeight(-300)"
          :headers="headers"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          fixed-header
          :footer-props= "getDataTableFooterProps('LoginReportsTable')"
          :items-per-page="-1"
      >
        <template v-slot:item="{ item }">
          <tr class="py-0 my-0">
            <td class="text-left font-size12-5" style="height:10px !important;">{{ medicalStaff }}</td>
            <td class="text-left  mx-0 px-0 font-size12-5" style="height:10px !important;">
              {{ authStatus(item.eventOrigin) }}
            </td>
            <td class="text-center  mx-0 px-0 font-size12-5" style="height:10px !important;">
              {{ formatDate(item.eventTimestamp) }}
          </td>
          </tr>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>
<script>
import { formatterFilter } from '@/utils/luxon-formater';
import { mapGetters } from 'vuex';
import { getDataTableFooterProps } from '@/utils/utilities';
export default {
  props: ['items'],
  computed: {
    ...mapGetters({ pageHeight: 'pageHeight', appBarBillingDate: 'filterbar/getBillingDate' }),
  },
  data () {
    return {
      loading: false,
      fullScreen: false,
      medicalStaff: '',
      selectedDateForReports: new Date().toISOString().substr(0, 10),
      expandedItems: [],

      expandClicked: false,
      headers: [
        { text: this.$t('staff'), sortable: false, class: 'secondary px-3', align: 'start' },
        { text: this.$t('status'), sortable: false, class: 'secondary px-1', align: 'start' },
        { text: this.$t('time'), sortable: false, class: 'secondary px-9', align: 'center' },
      ],
    };
  },
  methods: {
    getDataTableFooterProps,
    async getAuditReportDataLoginsAll (item) {
      this.medicalStaff = item.staffFullname;
      this.loading = true;
      let date = null;
      if (this.appBarBillingDate === null) {
        date = this.selectedDateForReports;
      } else {
        date = this.appBarBillingDate;
      }

      let year, month, day;
      if (this.appBarBillingDate || this.selectedDateForReports) {
        const selectedDate = date.split('-');
        if (selectedDate !== null && selectedDate !== undefined) {
          year = parseInt(selectedDate[0]);
          month = parseInt(selectedDate[1]);
          day = parseInt(selectedDate[2]);
        }
      } else {
        const currentDate = new Date();
        year = currentDate.getFullYear();
        month = currentDate.getMonth() + 1;
        day = currentDate.getDate();
      }

        const body = {
        staffId: item.staffId,
        year: year,
        month: month,
        day: day,
    };
      await this.$store.dispatch('reportsRepo/getAuditReportDataLoginsAll', body).then(res => {
        this.expandedItems = res.data;
        this.loading = false;
        // TODO - HANDLE RESPONSE!
      });
    },
    authStatus (status) {
      switch (status) {
        case 'signin':
          return this.$t('log-in');
        case 'signinwithtoken':
          return this.$t('re-logging');
        case 'signout':
          return this.$t('log-out');
        default:
          return 'Unknown status';
      }
    },
    formatDate (dateString) {
      return formatterFilter(
          dateString,
          'time_am_pm',
          this.timeLocalization,
          null,
      );
    },
    toggleFullScreen (val, item) {
      if (val === 'fullscreen') {
        this.$emit('toggle-full-screen');
        this.expandClicked = !this.expandClicked;
        this.expandedItems = [];
        this.fullScreen = true;
      } else {
        this.$emit('toggle-full-screen');
        this.expandClicked = !this.expandClicked;
        this.fullScreen = false;
        this.getAuditReportDataLoginsAll(item);
      }
    },
  },
};
</script>

<style>
th{
  padding:0px !important;
  padding-left:20px !important;
  height:5px !important;
}
</style>
