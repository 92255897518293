<template>
  <v-card
      class="mx-auto"
      elevation="0"
      v-if="selectedMedicalInstitution !== null">
    <v-card-title class="card-header" id="fixed-card-title">
      <v-row class="justify-end d-flex" dense>
        {{$t('viewOrganization')}}
        <v-spacer></v-spacer>
        <v-icon @click="synchronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
        <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
      </v-row>
    </v-card-title>
    <v-container class="">
      <v-card-text class="pt-2">
        <v-row dense class="data-table-outline  text-left border-radius4 pa-2">
          <v-col class="text-left pl-0" cols="9">
            <span class="font-weight-medium ml-1">{{ selectedMedicalInstitution?.institutionName }}</span>
          </v-col>
          <v-col class="justify-end align-center d-flex" cols="3">
            <v-icon small :class="{'green-icon': 1 === 1}" class="mr-2">mdi-checkbox-blank-circle</v-icon>
            <span class="font-weight-medium color-green">{{$t('active-caps')}}</span>
          </v-col>
          <v-col class="text-left" cols="12">
            <span >{{ selectedMedicalInstitution?.address }}</span>
          </v-col>
        </v-row>
<!--        <v-progress-circular-->
<!--            v-if="loadOffices"-->
<!--            :size="30"-->
<!--            :width="5"-->
<!--            color="primary"-->
<!--            indeterminate-->
<!--        ></v-progress-circular>-->
<!--          <v-list dense class=" rounded-sm text-left">-->
<!--            <v-list-item v-for="(item, index) in relatedHospitals" :key="item.id" :class="{'showhospitals-border-bottom': index !== relatedHospitals.length - 1 }">-->
<!--              <v-list-item-title>-->
<!--                {{ item.hospitalName }}-->
<!--              </v-list-item-title>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
        <v-row dense class="data-table-outline my-4 text-left border-radius4 pa-2">
          <v-col cols="12">
            <span class="view-organization-font">{{$t('description')}}</span>
          </v-col>
          <v-col cols="12">
            <span class="hospital-font">{{ selectedMedicalInstitution?.description }}</span>
          </v-col>
        </v-row>
        <v-row dense class="data-table-outline my-4 pa-2 text-left">
          <v-col class="text-left font-bold py-2" cols="12">
            <span class="contact-organization">{{$t('contact-person')}}</span>
          </v-col>
          <v-col class="text-left pt-0 pb-0" cols="12">
            <span class="pb-0 font-weight-medium font-size12-5">{{ selectedMedicalInstitution?.contactPerson }}</span>
          </v-col>
          <v-col class="text-left align-center d-flex py-0" cols="12">
            <v-icon small class="mr-2 color-primary">mdi-email</v-icon>
            <span class="color-primary pl-0">{{ selectedMedicalInstitution?.contactEmail }}</span>
          </v-col>
          <v-col class="text-left align-center pt-0 d-flex" cols="12">
            <v-icon small class="mr-2 color-primary">mdi-phone</v-icon>
            <span class="color-primary pl-0">{{ selectedMedicalInstitution?.contactPhone }}</span>
          </v-col>
        </v-row>
        <v-row dense class="data-table-outline d-flex my-4 pa-2 justify-space-between">
          <div class="d-flex align-center">
            <span class="mx-2">{{$t('enabled-assessments')}}</span>
            <v-icon color="green" v-if="selectedMedicalInstitution.wellnessFlagDefault">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-icon color="primary" class="mr-2" v-if="!selectedMedicalInstitution.wellnessFlagDefault">
              mdi-close-circle
            </v-icon>
          </div>

          <div class="d-flex align-center">
            <span class="mx-2">{{$t('enabled-rtm')}}</span>
            <v-icon color="green" v-if="selectedMedicalInstitution.useG3002ForRTM">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-icon color="primary" v-if="!selectedMedicalInstitution.useG3002ForRTM">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-btn @click="getOfficesForThisMedicalInstitution" block outlined depressed class="my-5 primary2">
          <img :src="hospitalWhite" height="25px">
          <span class="capitalize-text white--text">{{$t('view-offices')}}</span>
        </v-btn>
        <v-data-table
            :loading="loadOffices"
            v-if="showHospitals"
            :headers="headers"
            class="pa-1 pointer elevation-2"
            :no-data-text="$t('no-data-available')"
            :loading-text="$t('loading')"
            :items="relatedHospitals"
            sort-by="id"
            item-key="id"
            fixed-header
            dense
            :height="pageHeight(-500)"
            hide-default-footer
            :footer-props= "getDataTableFooterProps('medicalInstitutionsTable')"
            :items-per-page="-1"
        >
          <template v-slot:item="{item}">
            <tr>
              <td class="text-left pl-3 font-weight-medium">{{item.hospitalName}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hospitalBlack, hospitalWhite } from '@/assets';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
  name: 'DashboardCoreAppBar',
  components: {
  },
  data: () => ({
    hospitalBlack: hospitalBlack,
    relatedHospitals: [],
    showHospitals: false,
    loadOffices: false,

  }),
  computed: {
    ...mapGetters({
      selectedMedicalInstitution: 'medicalInstitutions/getSelectedMedicalInstitution',
      detailedMedicalInstitution: 'medicalInstitutions/getDetailedMedicalInstitution',
      pageHeight: 'pageHeight',
    }),
    hospitalWhite () {
      return hospitalWhite;
    },
    headers () {
      const headers = [
        { text: this.$t('name'), value: 'fullName',	class: 'table-header', align: 'start pl-3' },
      ];
      return headers;
    },
  },
  methods: {
    getDataTableFooterProps,
    ...mapActions({
      getMedicalInstitutionById: 'medicalInstitutions/getMedicalInstitutionById',
    }),
    closeDrawer () {
      this.$store.commit('medicalInstitutions/SELECTED_MEDICAL_INSTITUTION');
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
    synchronizeDrawers () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'medicalinstitution',
        item: this.selectedMedicalInstitution,
      });
    },
    async getOfficesForThisMedicalInstitution () {
      this.loadOffices = true;
      this.showHospitals = !this.showHospitals;
      if (this.showHospitals) {
        await this.getMedicalInstitutionById(this.selectedMedicalInstitution.id);
        this.relatedHospitals = this.detailedMedicalInstitution.hospitalsData;
      }
      this.loadOffices = false;
    },
  },
  beforeDestroy () {
    this.showHospitals = false;
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
};
</script>
