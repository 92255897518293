<template>
  <v-row dense class="overflow-y-hidden">
    <v-row>
      <v-dialog v-model="editEmailDialog" style="border: 100px white solid" max-width="400px"  transition="dialog-top-transition">
        <v-card class="pt-4 px-2 pb-2">
          <v-card-title class="d-flex justify-center align-center">
            <v-row  dense>
              <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="white" style="font-size: 20px" class="my-0">mdi-pencil</v-icon>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('areYouSure') }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-center pb-1">{{ $t('editEmailSure') }}</v-card-text>
          <v-card-actions class="mb-2">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                    v-if="patientEmailChange"
                    outlined
                    dense
                    value="patientEmailChange.id"
                    hide-details
                    v-model="patientEmailChange.email"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn class="save-button-style" @click="editInvalidEmail">{{ $t("save") }}</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn outlined depressed class="cancel-button-style" block @click="closeEditEmailDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmDialog" max-width="400px" transition="dialog-top-transition" persistent>
        <v-card>
          <v-card-title class="mx-auto primary2 py-1 font-size16 text-color-white">{{$t('change')}} {{$t('status')}}</v-card-title>
          <v-card-text class="py-5">{{$t('changeStatusQuestion')}}</v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn @click="confirmToggleStatus" color="primary2" class="text-color-white">{{ $t('yes') }}</v-btn>
            <v-btn @click="cancelToggleStatus">{{ $t('no') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-col cols="12">
      <v-data-table
          :loading="loading"
          :headers="headers"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          :headers-length="headers.length"
          :search="appBarSearch"
          :items="filteredUsers"
          :footer-props= "getDataTableFooterProps('patients')"
          :items-per-page="-1"
          sort-by="id"
          item-key="id"
          fixed-header
          dense
          :height="pageHeight(160)"
          class="medical-staff-data-table"
        >
        <template v-slot:item="{ item, index }">
          <tr :class="getRowsForDataTable(index)" @click="userDetails(item)">
            <td class="pl-2">
              <v-row dense class="d-flex align-center py-1">
                <v-col class="pl-2">
                  <v-row dense>
                    <v-icon :class="getStatusIcons(item.status)" small>mdi-checkbox-blank-circle</v-icon>
                    <span class="px-2 table-font-size">{{ item.firstName }} {{ item.lastName }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </td>
            <td class="table-font-size px-0">
              <v-chip  label pill x-small color="primary">{{ calculateAge(item.birthdate) }}</v-chip>
            </td>
            <td class="table-font-size px-0">
              <span>{{ item.gender === 'M' ? $t('male') : $t('female') }}</span>
            </td>
            <td class="table-font-size">
              <v-icon size="15" color="primary">mdi-email</v-icon>
              <span>{{ item.email }}</span>
            </td>
            <td class="px-0">
              <div class="d-flex px-0 mx-0 align-center justify-center">
                <v-tooltip
                    v-for="device in allDevices"
                    :key="device.deviceId"
                    top
                    :color="isDeviceAssigned(item, device.deviceId) ? 'primary' : 'grey'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                        class="mx-1"
                        :src="require('@/assets/icons/' + getDeviceIcon(item, device.deviceId))"
                        max-width="17"
                        v-bind="attrs"
                        v-on="on"
                    ></v-img>
                  </template>
                  <span>{{ device.deviceName }}</span>
                </v-tooltip>
              </div>
            </td>
            <td>
              <v-row dense class="d-flex align-center py-1">
                <v-col class="text-left" cols="10">
                  <v-row dense>
                    <v-tooltip top :color="item.isRpmEnabled ? 'primary' : ''">
                      <template v-slot:activator="{on}">
                      <span v-on="on" class="font-weight-medium">
                        <i v-if="item.isRpmEnabled" class="mdi mdi-checkbox-blank mdi-18px color-primary"></i>
                        <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></i>
                      </span>
                      </template>
                      <span>{{item.isRpmEnabled ? 'RPM Enrolled' : 'Not RPM Enrolled'}}</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </td>
            <td>
              <v-row dense class="d-flex align-center py-1">
                <v-col class="text-left" cols="10">
                  <v-row dense>
                    <v-tooltip top :color="item.isRtmEnabled ? 'primary' : ''">
                      <template v-slot:activator="{on}">
                      <span v-on="on" class="font-weight-medium">
                        <i v-if="item.isRtmEnabled" class="mdi mdi-checkbox-blank mdi-18px color-primary"></i>
                        <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></i>
                      </span>
                      </template>
                      <span>{{item.isRtmEnabled ? 'RTM Enrolled' : 'Not RTM Enrolled'}}</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </td>
            <td>
              <v-row v-if="item.status !== 99" dense class="d-flex align-center py-1">
                <v-col class="text-left" cols="10">
                  <v-row dense>
                    <span class="pl-4 font-weight-medium">
                      <i v-if="item.patientHasDoneReadingForToday" class="mdi mdi-checkbox-blank mdi-18px color-primary"></i>
                      <i v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></i>
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </td>
            <td class="pl-0">
              <v-row v-if="item.status !==-1  && item.status !== 99  && item.status !==10  ">
                <v-switch  dense readonly  @click.stop="setUserStatus(item)"  v-model="item.status" class="mt-n1 ml-3" hide-details></v-switch>
              </v-row>
            </td>
            <td v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'" class="pr-0">
              <div v-if="item.status !== 0 && item.status !== 99" :disabled="!item.hasMedicalTeam">
                <div v-if="item.status !== 1" class="text-left">
                  <v-tooltip color="primary" left>
                    <template v-slot:activator="{ on }">
                      <v-icon size="20" v-on="on" @click.stop="sendActivationEmail(item, 'USER_CONFIRMATION')" color="primary">
                        mdi-email
                      </v-icon>
                    </template>
                    <span>{{ $t('validateEmail') }}</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip left color="primary">
                    <template v-slot:activator="{on}">
                      <v-icon class="float-left" @click.stop="sendActivationEmail(item, 'RESET_PASSWORD')" color="primary" size="23" v-on="on">mdi mdi-lock-reset</v-icon>
                    </template>
                    <span>{{$t('reset-password')}}</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:footer.prepend>
          <span class="font-weight-bold mx-3">{{ $t('table-legend') }}:</span>
          <v-tooltip color="green" top>
            <template v-slot:activator="{ on }">
              <div class="d-flex">
                <span class="font-weight-medium font-size12-5">{{ $t('active') }}</span>
                <v-icon class="mdi-18px color-green px-2" v-on="on">mdi-checkbox-blank-circle</v-icon>
              </div>
            </template>
            <span>{{ $t('active') }}</span>
          </v-tooltip>
          <v-tooltip color="gray" top>
            <template v-slot:activator="{ on }">
              <div class="d-flex">
                <span class="font-weight-medium font-size12-5">{{ $t('inactive') }} / {{ $t('deleted') }}</span>
                <v-icon class="mdi-18px color-gray px-2" v-on="on">mdi-checkbox-blank-circle</v-icon>
              </div>
            </template>
            <span class="text--primary">{{ $t('inactive') }} / {{ $t('deleted') }}</span>
          </v-tooltip>
          <v-tooltip color="black" top>
            <template v-slot:activator="{ on }">
              <div>
                <span class="font-weight-medium font-size12-5">{{$t('deceased')}}</span>
                <v-icon class="mdi-18px color-black px-2" v-on="on">mdi-checkbox-blank-circle</v-icon>
              </div>
            </template>
            <span>{{ $t('deceased') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mailPurple, phonePurple } from '@/assets';
import { showSuccessAlert, showErrorAlert, getDataTableFooterProps, getRowsForDataTable } from '@/utils/utilities';

export default {
	data () {
		return {
      allDevices: [
        { deviceId: 1, deviceName: 'Thermometer' },
        { deviceId: 2, deviceName: 'Oximeter' },
        { deviceId: 3, deviceName: 'Blood Pressure' },
        { deviceId: 6, deviceName: 'Glucose Meter' },
        { deviceId: 5, deviceName: 'Weight Scale' },
      ],
      confirmDialog: false,
      userToToggle: null,
			loading: true,
      editEmailDialog: false,
      dialog: false,
      patientEmailChange: '',
			mailPurple: mailPurple,
			phonePurple: phonePurple,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			relatedPatients: (state) => state.hospitals.relatedPatients,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
      roleName: 'authentication/getRole',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
			appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
      appBarBillingTypeFilterVerifier: 'filterbar/getRpmValue',
		}),
		headers () {
			const headers = [
          { text: this.$t('name'), value: 'fullName',	class: 'table-header', align: 'start pl-3' },
				{ text: this.$t('header-age'), value: '', class: 'table-header pl-1', width: 50, sortable: false },
        { text: this.$t('header-sex'), value: 'gender', class: 'table-header', width: 50 },
				{ text: this.$t('email'), value: 'email', class: 'table-header pl-4', sortable: false },
        { text: this.$t('devices'), value: '', class: 'table-header pl-3 px-0', align: 'center', sortable: false },
        { text: 'RPM', value: 'isRpmEnabled', class: 'table-header px-0 pl-2 mx-0', width: 50, sortable: false },
        { text: 'RTM', value: 'isRtmEnabled', class: 'table-header px-0 pl-2 mx-0', width: 50, sortable: false },
        { text: this.$t('header-reading'), value: 'reading', class: 'table-header px-0 mx-0', width: 50, sortable: false },
        { text: this.$t('set-status'), value: 'status', class: 'table-header px-2', sortable: false, width: 50 },
				{ text: this.$t('actions'), value: 'emailReset', class: 'table-header', sortable: false, align: 'start pl-2', width: 50 },
			];
			return headers;
		},
    filteredUsers () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true;

      if (this.relatedPatients) {
        this.relatedPatients.forEach(patient => {
          patient.fullName = `${patient.firstName} ${patient.lastName}`;
        });
      }

      let calculateFilteredPatients = this.relatedPatients || [];

      // Filter based on status
      if (this.appBarUserStatusVerifier === 10 || this.appBarUserStatusVerifier === 99 || this.appBarUserStatusVerifier === 1 || this.appBarUserStatusVerifier === -1 || this.appBarUserStatusVerifier === 0) {
        calculateFilteredPatients = calculateFilteredPatients.filter(u => u.status === this.appBarUserStatusVerifier);
      }
      // Filter based on RPM status
      if (this.userData.isSequoiaUser === false || this.userData.isSequoiaUser === null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false;
        return calculateFilteredPatients;
      }
      if (this.appBarBillingTypeFilterVerifier === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false;
        return calculateFilteredPatients;
      }
      if (this.appBarBillingTypeFilterVerifier === 0) {
        calculateFilteredPatients = calculateFilteredPatients.filter(u => u.isRpmEnabled === true || u.isRtmEnabled === true);
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = false;
      return calculateFilteredPatients;
    },
  },
  async mounted () {
    // await this.getVerifiers();
    await this.getPatients();
  },
	methods: {
    getRowsForDataTable,
    // getOddRows (index) {
    //   if (index % 2 === 1) {
    //     return 'odd-row';
    //   }
    //   if (index % 2 === 0) {
    //     return 'even-row';
    //   }
    // },
    getDataTableFooterProps,
    getDeviceIcon (item, deviceId) {
      const iconMap = {
        1: { primary: 'thermometer_primary.svg', grey: 'thermometer_grey.svg' },
        2: { primary: 'oxymeter_primary.svg', grey: 'oxymeter_grey.svg' },
        3: { primary: 'blood_pressure_primary.svg', grey: 'blood_pressure_grey.svg' },
        5: { primary: 'scale_primary.svg', grey: 'scale_grey.svg' },
        6: { primary: 'glucose_meter_primary.svg', grey: 'glucose_meter_grey.svg' },
      };

      const treatmentPlanDevicesList =
          item?.patientActiveDevicesFromTreatmentPlan?.treatmentPlanDevicesList;

      if (!treatmentPlanDevicesList) {
        return iconMap[deviceId]?.grey;
      }

      const device = treatmentPlanDevicesList.find(device => device.deviceType === deviceId);
      return device ? iconMap[deviceId]?.primary : iconMap[deviceId]?.grey;
    },
    isDeviceAssigned (item, deviceId) {
      const treatmentPlanDevicesList =
          item?.patientActiveDevicesFromTreatmentPlan?.treatmentPlanDevicesList;

      if (!treatmentPlanDevicesList) {
        return false;
      }

      return treatmentPlanDevicesList.some(device => device.deviceType === deviceId);
    },
    getStatusIcons (status) {
      switch (status) {
        case 1:
          return 'color-green';
        case 99:
          return 'color-black';
      }
    },
    async confirmToggleStatus () {
      const requestData = {
        userId: this.userToToggle.id,
        statusValueToSet: this.userToToggle.status ? 0 : 1,
      };
         this.$store.dispatch('users/setUserStatus', requestData).then(res => {
           this.confirmDialog = false;
           this.getPatients();
        });
    },

    cancelToggleStatus () {
      this.confirmDialog = false;
      this.userToToggle = null;
    },
    async setUserStatus (item) {
      this.userToToggle = item;
      this.confirmDialog = true;
    },
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString?.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
    async userDetails (item) {
      item.medTeam = item.hasMedicalTeam === true ? 1 : 0;
      // Do not remove this , checks if the medical team exists when patient is selected.
      if (!item.medTeam) {
        showErrorAlert(this.$t('medicalTeamCheck'));
      } else {
        await this.$store.dispatch('users/getUserById', item.id)
            .then(() => {
              return this.$router.push({
                name: 'Patient Details',
                params: { id: `${item.id}` },
              });
            })
            .then(() => {
              return this.$store.dispatch('filterbar/resetSearch');
            });
      }
    },
    // Not sure about this part here , about the edit email ? Not used.
    editEmail (item) {
      this.editEmailDialog = true;
      this.patientEmailChange = item;
    },
    closeEditEmailDialog () {
      this.editEmailDialog = false;
    },
    editInvalidEmail () {
      const body = {
        userId: this.patientEmailChange.id,
        newEmailValue: this.patientEmailChange.email,
      };
      this.$store.dispatch('users/editInvalidEmail', body).then(async (res) => {
        if (res.data.resFlag) {
          showSuccessAlert(this.$t('success'));
          this.editEmailDialog = false;
        } else {
          showErrorAlert(this.$t('failed'));
        }
      });
    },
		async getPatients () {
      this.loading = true;
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				await this.$store.dispatch('hospitals/getRelatedPatientsV3', hospitalIdFromStore)
					.then(() => { this.loading = false; });
			} else if (this.roleName === 'ORGANIZATION_ADMIN') {
				this.loading = false;
			} else {
				this.loading = false;
			}
		},
		// async getVerifiers () {
		// 	const header = {
		// 		domainValue: 'USER_STATUS',
		// 	};
		// 	await this.$store.dispatch('verifiers/getVerifiersForDomain', header);
		// },
		closeDialog () {
			this.dialog = false;
			this.getPatients();
		},
		success (res) {
			this.dialog = false;
			this.getPatients();
			if (res.resFlag) {
        showSuccessAlert(this.$t('success'));
			} else {
        showSuccessAlert(this.$t('failed'));
			}
		},
		async sendActivationEmail (item, validationType) {
      if (validationType === null || validationType === undefined) {
        validationType = 'RESET_PASSWORD';
      }
			const sendValidationEmail = { email: item.email, validationType: validationType };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
            showSuccessAlert(this.$t('success'));
					} else {
            showSuccessAlert(this.$t('failed'));
					}
				});
		},
	},
};
</script>
