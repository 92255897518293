
<template>
  <v-card height="200" elevation="5" class="pa-5"  :style="dialog? 'margin-left:15px; margin-top:15px; !important' : ''">
    <div class="text-left">
      <span class="font-weight-bold">{{$t('scoring')}}</span>
      <br>
      <br>
      <span class="font-weight-medium font-size13">
     {{$t('MDQ-description')}}
      </span>
      <br>
      <ul class="font-weight-medium font-size13">
        <li>{{$t('MDQ-list-1')}}</li>
        <li>{{$t('MDQ-list-2')}}</li>
        <li>{{$t('MDQ-list-3')}}</li>
      </ul>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'MoodDisorderQuestionnaire',
  props: ['dialog'],
};
</script>

<style scoped>

</style>
