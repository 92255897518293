
<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
          v-if="billingsLinksList"
          :loading="reportDialogLoading"
          :headers="headers"
          :search="appBarSearch"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          :headers-length="headers.length"
          :items="billingsLinksList"
          :footer-props= "getDataTableFooterProps('partnerBillingReportsTable')"
          :items-per-page="-1"
          sort-by="id"
          item-key="id"
          fixed-header
          dense
          :height="pageHeight(150)"
          class="medical-staff-data-table elevation-3"
      >
        <template v-slot:item="{ item }">
          <tr :class="item.status === 0 ? 'secondary' : 'even-row'">
            <td class="text-left">
              <span class=" font-weight-medium font-size13 d-flex align-items-center">
                <v-img style="max-height: 18px; max-width:18px; margin-right:4px;"  :src="profileSettingsBlack"></v-img>
                {{ item.linkCreatedByFullname }}
              </span>
            </td>
<!--            <td class="text-left">-->
<!--              <v-icon size="15" color="primary">mdi-email</v-icon>-->
<!--              <span class=" font-weight-medium font-size13">{{ item.sendTo }}</span>-->
<!--            </td>-->
            <td class="text-left font-weight-medium font-size13" >
              {{ formatBillingDate(item.billingMonth, item.billingYear) }}
            </td>
            <td class="text-left">
              <v-icon size="20" color="primary">mdi-calendar-clock</v-icon>
              <span class=" font-weight-medium font-size13">{{ formatDate(item.expiresAt) }}</span>
            </td>
            <td class="text-left">
              <span class=" font-weight-medium font-size13">{{ checkStatus(item.status) }}</span>
            </td>
            <td class="text-left">
              <v-icon class=" font-weight-medium pl-4 font-size20"  :class="item.isEmailSent ? 'color-green' :'color-primary'">{{ checkIsEmailSent(item.isEmailSent) }}</v-icon>
            </td>
            <td class="text-left">
              <v-icon class=" font-weight-medium pl-7 font-size20" :class="item.isEmailDownloaded ? 'color-green' : 'color-primary'">{{ checkIsEmailDownloaded(item.isEmailDownloaded) }}</v-icon>
            </td>
            <td class="text-right">
              <v-btn  :disabled="!isButtonEnabled(item)"  x-small color="primary"  @click="downloadCSV(item.linkUrl)">Download CSV</v-btn>
            </td>
            <td class="text-right ">
              <v-icon :disabled="!isButtonEnabled(item)"  class="icon-img px-0 mx-0 pr-2 mdi-18px" color="black" @click="openPreviewDialog(item)">mdi-eye</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <ReportsDialog v-if="reportDialog" @close-dialog="reportDialog = false" :dialog.sync="reportDialog"></ReportsDialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { profileSettingsBlack } from '@/assets';
import ReportsDialog from '@/views/Client/components/ReportsDialog.vue';
import { getDataTableFooterProps } from '@/utils/utilities';
export default {
  name: 'BillingReports.vue',
  components: { ReportsDialog },
  data () {
    return {
      reportDialog: false,
      reportDialogLoading: true,
      dialogEmail: false,
      selectedItemToEmail: null,
      selectedItemId: null,
    };
  },
  computed: {
    profileSettingsBlack () {
      return profileSettingsBlack;
    },
    ...mapState({
      billingsLinksList: (state) => state.billings.billingsLinksList,
      authenticated: state => state.authentication.userData,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
      appBarBillingDate: 'filterbar/getBillingDate',
      appBarSearch: 'filterbar/getSearch',
    }),
    headers () {
      const headers = [
        { text: this.$t('created-by'), value: 'linkCreatedByFullname',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('billing-month'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('expires-at'), value: 'expiresAt',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('status'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('email-sent'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('email-downloaded'), value: 'sendTo',	class: 'table-header', align: 'start pl-4' },
        { text: this.$t('actions'), value: '',	class: 'table-header', align: 'end pr-7' },
        { text: this.$t('details'), value: '',	class: 'table-header', align: 'end', width: 20 },
      ];
      return headers;
    },
  },
  created () {
    this.getBillingOrganizationLinks();
    // this.getPartners();
  },
  methods: {
    getDataTableFooterProps,
    async openPreviewDialog (item) {
      this.reportDialogLoading = true;

      const request = {
        organizationId: item.organizationId,
        month: item.billingMonth,
        year: item.billingYear,
      };
      await this.$store.dispatch('billings/getCsvDataForMonth', request).then(resp => {
        this.reportDialog = true;
        this.reportDialogLoading = false;
      });
    },
    formatBillingDate (billingMonth, billingYear) {
      const date = new Date(billingYear, billingMonth - 1, 1); // Create a Date object with the first day of the month
      return date.toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric',
      });
    },
    isButtonEnabled (item) {
      return item.status === 1 && item.isEmailSent === true;
    },
    async downloadCSV (linkUrl) {
      try {
        const link = document.createElement('a');
        link.href = linkUrl;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        // console.error('Error initiating the download:', error);
      } finally {
        await this.getBillingOrganizationLinks();
      }
    },
    async getPartners () {
      const organizationId = this.roleName === 'SYSTEM_ADMIN' ? -1 : this.authenticated.organizationId;
      const body = {
        organizationId: organizationId,
      };

      await this.$store.dispatch('users/getPartners', body);
    },
    checkIsEmailDownloaded (download) {
      switch (download) {
        case false :
          return 'mdi-close-circle';
        case true :
          return 'mdi-check-circle';
      }
    },
    checkIsEmailSent (email) {
      switch (email) {
        case false :
          return 'mdi-close-circle';
        case true :
          return 'mdi-check-circle';
      }
    },
    formatDate (dateString) {
      const date = new Date(dateString); // Convert the string to a Date object
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    // 1-Active, 2-Expired, 3-Used
    checkStatus (status) {
      switch (status) {
        case 1 :
          return this.$t('active');
        case 2:
          return this.$t('expired');
        case 3:
          return '';
        default:
          return 'Expired';
      }
    },
    async deleteSurveyById (item) {
      this.selectedItemId = item.id;
      this.reportDialog = true;
    },
    async sendEmail (item) {
      this.selectedItemToEmail = item;
      this.dialogEmail = true;
    },
    syncronizeDrawers (item) {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'links',
        item: item,
      });
    },
    closeDeleteDialog () {
      this.reportDialog = false;
      // this.surveyId = '';
    },
    closeEmailDialog () {
      this.dialogEmail = false;
      // this.surveyId = '';
    },
    async getBillingOrganizationLinks () {
      this.reportDialogLoading = true;
      await this.$store.dispatch('billings/getBillingLinksForOrganization');
      this.reportDialogLoading = false;
    },
  },

};
</script>
