<template>
<div>
  <v-row>
    <v-card class="my-3 mx-2 ml-3 mr-3 elevation-1"  width="100%" color="primary2">
      <v-btn @click="toggleFullScreen('fullscreen')" class="my-n2 py-5 mx-n4" icon color="white"  absolute right>
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <span class="d-flex justify-center font-size13 font-weight-bold mt-1 white-color">{{$t('measurements')}}</span>
    </v-card>
    <v-data-table
        v-if="!expandClicked || fullScreen"
        class="mx-2 full-width medical-staff-data-table elevation-1"
        :items="items"
        :headers="headers"
        :height="expandClicked ? pageHeight(75) : dataTableHeight"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        fixed-header
        :footer-props= "getDataTableFooterProps('measurementsReportsTable')"
        :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr class="py-0 my-0">
          <td class="text-left font-size12-5" style="height:10px !important;">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on }">
                <v-icon @click="toggleFullScreen('api', item)" v-on="on" small color="blue" class="mdi mdi-information-outline"></v-icon>
              </template>
              <span>Show More...</span>
            </v-tooltip>
            {{ item.staffFullname }}
          </td>          <td class="text-left mx-0 px-0 font-size12-5" style="height:10px !important;">
            <div v-for="(measurement, index) in item.measurements" :key="index" class="px-0 mx-0" :style="{ borderBottom: index !== item.measurements.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ measurement.patientFullName }}
            </div>
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(measurement, index) in item.measurements" :key="index" :style="{ borderBottom: index !== item.measurements.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ getDeviceName(measurement.deviceType) }}
            </div>
          </td>
          <td class=" font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(measurement, index) in item.measurements" :key="index" :style="{ borderBottom: index !== item.measurements.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ formatMeasurement(measurement) }}
            </div>
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
            <div v-for="(measurement, index) in item.measurements" :key="index" :style="{ borderBottom: index !== item.measurements.length - 1 ? '1px solid #ccc' : 'none' }">
              {{ formatDate(measurement.measuredAt) }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <span class="font-size12-5 pa-1 mt-n3 mx-auto" v-if="expandClicked && !fullScreen && expandedItems.length === 0">{{ $t('no-data-available') }}</span>

    <v-data-table
        v-if="expandClicked && expandedItems.length !== 0"
        :loading="loading"
        class="mx-2 full-width medical-staff-data-table elevation-1"
        :items="expandedItems"
        :headers="headers"
        :height="expandClicked ? pageHeight(75) : ''"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        fixed-header
        :footer-props= "getDataTableFooterProps('measurementsReportsTable')"
        :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr class="py-0 my-0">
          <td class="text-left font-size12-5" style="height:10px !important;">{{ medicalStaff }}</td>
          <td class="text-left mx-0 px-0 font-size12-5" style="height:10px !important;">
              {{ item.patientFullName }}
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
              {{ getDeviceName(item.deviceType) }}
          </td>
          <td class=" font-size12-5 px-0" style="height:10px !important;">
              {{ formatMeasurement(item) }}
          </td>
          <td class="font-size12-5 px-0" style="height:10px !important;">
              {{ formatDate(item.measuredAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-row>
</div>
</template>
<script>
import { formatterFilter } from '@/utils/luxon-formater';
import { mapGetters } from 'vuex';
import {
  bloodPressureBlack,
  cholesterolMeterBlack,
  glucoseMeterBlack,
  oxymeterBlack,
  scaleBlack,
  thermometerBlack,
} from '@/assets';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
 props: ['items'],
  computed: {
    ...mapGetters({ userSettings: 'authentication/getUserSettings', pageHeight: 'pageHeight', appBarBillingDate: 'filterbar/getBillingDate' }),
    dataTableHeight () {
      if (this.$vuetify.breakpoint.lgAndDown) {
        return 250; // Set height for small screens and down
      } else {
        return 265; // Set height for larger screens
      }
    },
  },
 data () {
   return {
     showDialog: false,
     fullScreen: false,
     loading: false,
     expandedItems: [],
     medicalStaff: null,
     expandClicked: false,
     selectedDateForReports: new Date().toISOString().substr(0, 10),
     selectedItem: null,

     thermometer: thermometerBlack,
     bloodPressure: bloodPressureBlack,
     oximeter: oxymeterBlack,
     glucoseMeter: glucoseMeterBlack,
     weightScale: scaleBlack,
     cholesterolMeter: cholesterolMeterBlack,
     headers: [
       { text: this.$t('staff'), sortable: false, class: 'secondary px-3', align: 'start' },
       { text: this.$t('patient-name'), sortable: false, class: 'secondary px-0', align: 'start' },
       { text: this.$t('device-type'), sortable: false, class: 'secondary px-1', align: 'center' },
       { text: this.$t('value'), sortable: false, class: 'secondary px-1', align: 'center' },
       { text: this.$t('measured-at'), sortable: false, class: 'secondary px-2', align: 'center' },
     ],
   };
 },

  methods: {
    getDataTableFooterProps,
    async getauditreportdatameasurementsall (item) {
      this.medicalStaff = item.staffFullname;
      this.loading = true;
      let date = null;
      if (this.appBarBillingDate === null) {
        date = this.selectedDateForReports;
      } else {
        date = this.appBarBillingDate;
      }

      let year, month, day;
      if (this.appBarBillingDate || this.selectedDateForReports) {
        const selectedDate = date.split('-');
        if (selectedDate !== null && selectedDate !== undefined) {
          year = parseInt(selectedDate[0]);
          month = parseInt(selectedDate[1]);
          day = parseInt(selectedDate[2]);
        }
      } else {
        const currentDate = new Date();
        year = currentDate.getFullYear();
        month = currentDate.getMonth() + 1;
        day = currentDate.getDate();
      }

      const body = {
        hospitalId: item.officeId,
        staffId: item.staffId,
        year: year,
        month: month,
        day: day,
      };
      await this.$store.dispatch('reportsRepo/getauditreportdatameasurementsall', body).then(res => {
        this.expandedItems = res.data;
        this.loading = false;
        // TODO - HANDLE RESPONSE!
      });
    },
    formatDate (dateString) {
      return formatterFilter(
          dateString,
          'time_am_pm',
          this.timeLocalization,
          null,
      );
    },
    getDeviceIcon (deviceType) {
        switch (deviceType) {
          case 1:
            return this.thermometer;
          case 2:
            return this.oximeter;
          case 3:
            return this.bloodPressure;
          case 5:
            return this.weightScale;
          case 6:
            return this.glucoseMeter;
          case 7:
            return this.cholesterolMeter;
        }
    },
    getDeviceName (deviceType) {
      switch (deviceType) {
        case 1:
          return this.$t('thermometer');
        case 2:
          return this.$t('oximeter');
        case 3:
          return this.$t('bloodPressure');
        case 5:
          return this.$t('weight-scale');
        case 6:
          return this.$t('blood-glucose');
        case 7:
          return this.$t('cholesterol-meter');
      }
    },
    formatMeasurement (data) {
      const measurements = JSON.parse(data.measureDataJson);

      switch (data.deviceType) {
        case 1:
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return measurements?.Temperature_C + ' °C';
          } else if (this.userSettings.Units.toLowerCase() === 'imperial') {
            return measurements.Temperature_F + ' °F';
          }
          break;
        case 3:
          return measurements.Dias !== undefined ? measurements.Sys + '/' + measurements.Dias + ' mmHg' : '';
        case 2:
          return measurements?.BPM !== undefined ? measurements?.Spo2 + ' SpO2 ' : '';
        case 5:
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return measurements.weight_kg + ' kg';
          } else return measurements.weight_lbs + ' lb';
        case 6:
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return measurements.glucose_mmolL + ' mmol/L';
          } else return measurements.glucose_mgL + ' mg/dL';
        case 7:
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return measurements.cholesterol_mmolL + ' mmol/L';
          } else return measurements.cholesterol_mgL + ' mg/dL';
      }
    },
    toggleFullScreen (val, item) {
      if (val === 'fullscreen') {
        this.$emit('toggle-full-screen');
        this.expandClicked = !this.expandClicked;
        this.expandedItems = [];
        this.fullScreen = true;
      } else {
        this.$emit('toggle-full-screen');
        this.expandClicked = !this.expandClicked;
        this.fullScreen = false;
        this.getauditreportdatameasurementsall(item);
      }
    },
  },
};
</script>

<style>
th{
  padding:0px !important;
  padding-left:20px !important;
  height:5px !important;
}
</style>
