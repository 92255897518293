<template>
  <v-dialog max-width="2000" v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card>
      <v-card-title class="card-header-add font-weight-bold px-2 py-2 mb-5">
        <span class="font-size15">{{ $t('Reports Dialog') }} : {{ formatBillingDate(getCsvDataPreview[0].month,getCsvDataPreview[0].year) }} - {{ getCsvDataPreview[0].orgName }} </span>
        <v-spacer />
        <v-icon color="white" @click="closeDialog">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-actions class="mb-2">
        <v-card width="100%">
          <v-data-table
              :items="getCsvDataPreview"
              :headers="reportsHeaders"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :headers-length="reportsHeaders.length"
              :footer-props= "getDataTableFooterProps('reportsDialogTable')"
              :items-per-page="-1"
              fixed-header
              dense
              :height="pageHeight(70)"
              class="pa-1 medical-staff-data-table">
            <template v-slot:item="{item}">
             <tr>
<!--               <td class="font-size11 px-0">-->
<!--                {{ formatBillingDate(item.month,item.year) }}-->
<!--               </td>-->
<!--               <td class="font-size11 px-0">-->
<!--                 {{item.orgName}}-->
<!--               </td>-->
               <td class="font-size12 px-0 text-left">
                 {{item.officeName}}
               </td>
               <td class="font-size12 px-0 text-left">
                 {{item.patientFirstName}} {{item.patientLastName}}
               </td>
               <td class="font-size12 px-0 text-left">
                 {{ formatDate(item.birthdate) }}
               </td>
               <td class="font-size12 px-0 text-left">
                 {{getGender(item.gender)}}
               </td>
               <td class="font-size12 px-0 text-left">
                 {{item.orderingProviderName}}
               </td>
               <td class="font-size12 px-0 text-left">
                 <div  class="overflow-y-auto">
                   {{ item.problemList.replace(/\s\|\s+/g, " ") }}
                 </div>
               </td>
               <td class="font-size12 px-0 text-left">
                 {{item.cpt99453_DeviceSetupFee}}
               </td>
               <td class="font-size12 px-0 text-left">
                 {{formatDate(item.cpt99453_16thReadingDate)}}
               </td>
               <td class="font-size12 px-0 text-left">
                 <v-icon size="20" v-if="item.cpt99453_Billable === 'Yes'">mdi-checkbox-blank</v-icon>
                 <v-icon size="20" v-else>mdi-checkbox-blank-outline</v-icon>
               </td>
               <td class="font-size12 px-0 text-left">
                 {{item.cpt99454_TotalMeasurements}}
               </td>
               <td class="font-size12 px-0 text-left">
                 <v-icon size="20" v-if="item.cpt99454_Billable === 'Yes'">mdi-checkbox-blank</v-icon>
                 <v-icon size="20" v-else>mdi-checkbox-blank-outline</v-icon>
               </td>
               <td class="font-size12 px-0 text-left">
                 <v-icon size="20" v-if="item.cpt99457_Billable === 'Yes'">mdi-checkbox-blank</v-icon>
                 <v-icon size="20" v-else>mdi-checkbox-blank-outline</v-icon>
               </td>
               <td class="font-size12 px-0 text-left">
                 <v-icon size="20" v-if="item.cpt99458_Billable === 'Yes'">mdi-checkbox-blank</v-icon>
                 <v-icon size="20" v-if="item.cpt99458_Billable === 'Yes' || item.cpt99458_Billable === 'No'">mdi-checkbox-blank-outline</v-icon>

                 <v-icon size="20" v-if="item.cpt99458_Billable === 'Yes x2'">mdi-checkbox-blank</v-icon>
                 <v-icon size="20" v-if="item.cpt99458_Billable === 'Yes x2'">mdi-checkbox-blank</v-icon>
               </td>

               <td class="font-size12 px-0 text-left">
                 {{item.cpt99457_Cpt99458_TotalMinutes}}
               </td>
             </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-card-actions>
      <!--      <v-btn @click="makePatientActive">Make him alive</v-btn>-->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getDataTableFooterProps } from '@/utils/utilities';
export default {
  props: ['dialog', 'detailedUser'],
  data () {
    return {
      localDialog: this.dialog,

    };
  },
  computed: {
    ...mapState({
      getCsvDataPreview: (state) => state.billings.getCsvDataPreview,

    }),
    ...mapGetters({
    pageHeight: 'pageHeight',
    }),
    reportsHeaders () {
      let headers = [];
      headers = [
        // { text: this.$t('date'), value: 'Year', class: 'color-header font-size11 px-0', align: 'center' },
        // { text: this.$t('organization-name'), value: 'OrgName', class: 'color-header font-size11 px-0', align: 'center' },
        { text: this.$t('office-name'), value: 'OfficeName', class: 'color-header font-size11 px-0' },
        { text: this.$t('patient-name'), value: 'PatientFirstName', class: 'color-header font-size11 px-0' },
        { text: this.$t('birthdate'), value: 'Birthdate', class: 'color-header px-0' },
        { text: this.$t('gender'), value: 'Gender', class: 'color-header px-0' },
        { text: this.$t('Provider Name'), value: 'OrderingProviderName', class: 'color-header px-0' },
        { text: this.$t('problem-list'), value: 'ProblemList', class: 'color-header px-0' },
        { text: this.$t('99453 Setup fee'), value: 'Cpt99453_DeviceSetupFee', class: 'color-header px-0' },
        { text: this.$t('99453 16th Reading date'), value: 'Cpt99453_16thReadingDate', class: 'color-header px-0', width: '100' },
        { text: this.$t('99453'), value: 'Cpt99453_Billable', class: 'color-header px-0', width: '50' },
        { text: this.$t('99454 Measurements'), value: 'Cpt99454_TotalMeasurements', class: 'color-header px-0', width: '100' },
        { text: this.$t('99454'), value: 'Cpt99454_Billable', class: 'color-header px-0', width: '50' },
        { text: this.$t('99457'), value: 'Cpt99457_Billable', class: 'color-header px-0', width: '50' },
        { text: this.$t('99458'), value: 'Cpt99458_Billable', class: 'color-header px-0', width: '50' },
        { text: this.$t('99457,99458 Minutes'), value: 'Cpt99457_Cpt99458_TotalMinutes', class: 'color-header px-0' },
        { text: '', value: '', class: 'color-header', sortable: false },
      ];
      headers.pop(); // This removes the last empty header, if needed
      return headers;
    },
  },
  methods: {
    getDataTableFooterProps,
    getGender (gender) {
      switch (gender) {
        case 'F':
          return this.$t('female');
        case 'M':
          return this.$t('male');
      }
    },
    formatDate (dateString) {
      const date = new Date(dateString); // Convert the string to a Date object
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    formatBillingDate (billingMonth, billingYear) {
      // Convert the full month name to a numeric value
      const monthNames = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
        'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
      const monthIndex = monthNames.indexOf(billingMonth.toUpperCase());

      if (monthIndex === -1) {
        throw new Error('Invalid month name');
      }

      // Use the existing logic to format the date
      const date = new Date(billingYear, monthIndex, 1);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric',
      });
    },
    closeDialog () {
      this.$emit('close-dialog'); // emit event to update par
    },
  },
};
</script>

<style >

</style>
